import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { Typography, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import * as Yup from "yup";
import "../../../css/products.css";
import {
  urlEndpoint,
  publicKey,
  authenticationEndpoint,
} from "../../../Common/ImageKit/ImageKitCred";
import { IKContext, IKImage, IKUpload } from "imagekitio-react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  addProduct,
  updateProduct,
  deleteProductModifier,
} from "../../../Services/dbService";
import Autocomplete from "@mui/material/Autocomplete";
import {
  useAddons,
  useProductCategorySearch,
  useSpecificSubcatogories,
  useSpecificSubcatogoriesSetting,
  useProductsModifiers,
  useCategorySetting,
} from "../../../Common/Hooks/queryCalls";
import { Switch, FormControlLabel } from "@mui/material";

const AddProduct = ({
  providerId,
  setOpen,
  edit,
  specificProduct = null,
  refetch,
}) => {
  const [isActive, setIsActive] = React.useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searchCategory, setSearchCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [modifierId, setModifierId] = useState("");
  const [modifierName, setModifierName] = useState("");
  const [resIcon, setResIcon] = useState({});
  const [isHoveredIcon, setIsHoveredIcon] = useState(false);
  const [modifierType, setModifierType] = React.useState("");
  const [modifierPrice, setModifierPrice] = React.useState(0);
  const [modifierData, setModifierData] = useState([]);
  const [flag, setFlag] = useState(true);

  const handleChange = (event) => {
    setModifierType(event.target.value);
  };

  const { data, isLoading } = useProductCategorySearch({
    search: searchCategory,
  });

  const categoriesSetting = useCategorySetting({ categoryId });
  // console.log("categoriesSetting", categoriesSetting);
  // console.log("search data::", data);
  // console.log("currentCategoryId:", categoryId);

  const subCategory = useSpecificSubcatogories({ subId: categoryId });

  const subCategorySetting = useSpecificSubcatogoriesSetting({ subCategoryId });
  // console.log("subCategorySetting:", subCategorySetting?.data);

  // console.log("subCategories::", subCategory);

  const addons = useAddons({ providerId });
  // console.log("currentModifier:", { modifierId, modifierPrice, modifierType });

  // console.log("addons::", addons);
  // console.log("modifierData:", modifierData);

  const productsModifier = useProductsModifiers({
    menuId: specificProduct?._id,
  });

  // console.log("productsModifier:;", productsModifier);
  // console.log(flag);

  const validationSchema = Yup.object().shape({
    englishName: Yup.string().required(" Name is required"),
    arabicName: Yup.string().required("اسم  is required"),
    price: Yup.number().required("Price is required"),
    // salePrice: Yup.number(),
    // englishDescription: Yup.string().required(" Description is required"),
    // arabicDescription: Yup.string().required("اسم  is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  async function addNewProduct(data) {
    if (edit) {
      // console.log("editProduct ", data);
      setLoading(true);
      const result = await updateProduct({
        providerId,
        item: specificProduct,
        data,
        resIcon,
        categoryId,
        subCategoryId,
        modifierData,
        isActive
      });
      // console.log("Editresult:", result);
      if (result) {
        setLoading(false);
        setOpen(false);
        refetch();
      }
    } else {
      // console.log("addProduct ", data);
      setLoading(true);
      const result = await addProduct({
        providerId,
        data,
        resIcon,
        categoryId,
        subCategoryId,
        modifierData,
        isActive
      });
      // console.log("Addresult:", result);
      if (result) {
        setLoading(false);
        setOpen(false);
        refetch();
      }
    }
  }

  // async function deleteOneModifier(data) {
  //   const result = await deleteProductModifier({
  //     modifierId: data._id,
  //   });
  //   console.log("deletedone modifer:", result);
  //   productsModifier.refetch();
  // }
  // useEffect(() => {
  //   setModifierData([...modifierData,...productsModifier.data])

  // },[])

  useEffect(() => {
    setValue("englishName", specificProduct?.name?.en);
    setValue("arabicName", specificProduct?.name?.ar);
    setValue("englishDescription", specificProduct?.description?.en);
    setValue("arabicDescription", specificProduct?.description?.ar);
    setValue("price", specificProduct?.price);
    setValue("salePrice", specificProduct?.salePrice);
    setValue("minimumAddons", specificProduct?.minimumModifiers);
    setValue("maximumAddons", specificProduct?.maximumModifiers);
    setResIcon(specificProduct?.mainImage);
    setCategoryId(specificProduct?.categoryId);
    setSubCategoryId(specificProduct?.subCategoryId);
    setIsActive(specificProduct?.metadata?.isActive);
  }, []);

  useEffect(() => {
    if (flag && productsModifier.data) {
      setModifierData([...productsModifier.data]);
      setFlag(false);
    }
  }, [productsModifier.data]);

  const onErrorIcon = (err) => {
    // console.log("ErrorIcon::", err);
  };

  const onSuccessIcon = (res) => {
    // console.log("SuccessIcon", res);
    setResIcon(res);
    setImageUploading(false);
  };

  const onUploadProgress = (progress) => {
    // console.log("Progress", progress);
    setImageUploading(true);
  };

  // console.log("selected type:", modifierType);

  return (
    <div className="addon-div">
      <h4>Add Product</h4>{" "}
      <form>
        <div className="product-text-field">
          <div className="product-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="Name*"
              id="fullWidth"
              size="small"
              {...register("englishName", { required: true })}
              error={errors.englishName ? true : false}
              sx={styleTextField}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.englishName && errors.englishName?.message
                ? errors.englishName?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="product-text-field-row">
            <TextField
              fullWidth
              label="اسم*"
              id="fullWidth"
              size="small"
              {...register("arabicName", { required: true })}
              error={errors.arabicName ? true : false}
              sx={styleTextField}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.arabicName && errors.arabicName?.message
                ? errors.arabicName?.message.toString()
                : null}
            </Typography>
          </div>
        </div>
        <div className="product-text-field">
          <TextField
            fullWidth
            label="Description"
            id="fullWidth"
            size="small"
            {...register("englishDescription", { required: true })}
            error={errors.englishDescription ? true : false}
            sx={styleTextField}
          />
          <Typography
            variant="inherit"
            color="textSecondary"
            sx={{ textAlign: "center" }}
          >
            {errors &&
            errors.englishDescription &&
            errors.englishDescription?.message
              ? errors.englishDescription?.message.toString()
              : null}
          </Typography>
          <TextField
            fullWidth
            label="وصف"
            id="fullWidth"
            size="small"
            {...register("arabicDescription", { required: true })}
            error={errors.arabicDescription ? true : false}
            sx={styleTextField}
          />
          <Typography
            variant="inherit"
            color="textSecondary"
            sx={{ textAlign: "center" }}
          >
            {errors &&
            errors.arabicDescription &&
            errors.arabicDescription?.message
              ? errors.arabicDescription?.message.toString()
              : null}
          </Typography>
        </div>
        <div className="product-text-field">
          <div className="product-text-field-row">
            <TextField
              fullWidth
              label="Price*"
              id="fullWidth"
              size="small"
              {...register("price", { required: true })}
              error={errors.price ? true : false}
              sx={styleTextField}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.price && errors.price?.message
                ? errors.price?.message.toString()
                : null}
            </Typography>
          </div>

          <TextField
            fullWidth
            label="Sale Price"
            id="fullWidth"
            size="small"
            {...register("salePrice", { required: true })}
            error={errors.salePrice ? true : false}
            sx={styleTextField}
          />
          <Typography
            variant="inherit"
            color="textSecondary"
            sx={{ textAlign: "center" }}
          >
            {errors && errors.salePrice && errors.salePrice?.message
              ? errors.salePrice?.message.toString()
              : null}
          </Typography>
        </div>
        <div className="product-text-field">
          <TextField
            fullWidth
            label="Minimum Addons"
            id="fullWidth"
            size="small"
            {...register("minimumAddons", { required: true })}
            error={errors.minimumAddons ? true : false}
            sx={styleTextField}
          />
          <Typography
            variant="inherit"
            color="textSecondary"
            sx={{ textAlign: "center" }}
          >
            {errors && errors.minimumAddons && errors.minimumAddons?.message
              ? errors.minimumAddons?.message.toString()
              : null}
          </Typography>
          <TextField
            fullWidth
            label="Maximum Addons"
            id="fullWidth"
            size="small"
            {...register("maximumAddons", { required: true })}
            error={errors.maximumAddons ? true : false}
            sx={styleTextField}
          />
          <Typography
            variant="inherit"
            color="textSecondary"
            sx={{ textAlign: "center" }}
          >
            {errors && errors.maximumAddons && errors.maximumAddons?.message
              ? errors.maximumAddons?.message.toString()
              : null}
          </Typography>
        </div>
        <div className="product-text-field">
          <Autocomplete
            options={data || []}
            fullWidth
            size="small"
            value={categoriesSetting?.data?.label || ""}
            onChange={(event, newValue) => {
              setCategoryId(newValue?.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Category"
                size="small"
                sx={styleTextField}
                onChange={(newValue) =>
                  setSearchCategory(newValue.target.value)
                }
              />
            )}
          />

          {categoryId && (
            <Autocomplete
              options={subCategory?.data || []}
              fullWidth
              size="small"
              value={subCategorySetting?.data?.label || ""}
              onChange={(event, newValue) => {
                setSubCategoryId(newValue?.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Sub Category"
                  size="small"
                  sx={styleTextField}
                  // onChange={(newValue) =>
                  //   setSearchCategory(newValue.target.value)
                  // }
                />
              )}
            />
          )}
        </div>
        <div className="product-image-field">
          <h5>Image:</h5>
          {resIcon && resIcon.filePath ? (
            <div
              style={{
                width: 35,
                height: 30,
                display: "flex",
                alignItems: "center",
                marginTop: 10,
              }}
              onMouseEnter={() => setIsHoveredIcon(true)}
              onMouseLeave={() => setIsHoveredIcon(false)}
            >
              <div
                style={{
                  position: "relative",
                }}
              >
                <IKImage
                  urlEndpoint={urlEndpoint}
                  path={resIcon.filePath}
                  width="50px"
                  height="50px"
                />
                {resIcon.filePath && (
                  <div
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      zIndex: "1",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // setImage(null);
                      setResIcon(null);
                    }}
                  >
                    <AiOutlineCloseCircle
                      size={16}
                      color="red"
                      style={{
                        position: "absolute",
                        top: -10,
                        right: -10,
                        // color: 'red',
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <IKContext
              publicKey={publicKey}
              urlEndpoint={urlEndpoint}
              authenticationEndpoint={authenticationEndpoint}
            >
              {imageUploading ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <IKUpload
                  onError={onErrorIcon}
                  onSuccess={onSuccessIcon}
                  onUploadStart={onUploadProgress}
                  buttonProps={{ children: "+" }}
                />
              )}
            </IKContext>
          )}
        </div>
        <div className="product-text-field">
          {" "}
          <FormControlLabel
            control={
              <Switch
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
              />
            }
            label="is Active"
          />
        </div>
        <div className="product-text-field">
          {" "}
          <TableContainer component={Paper}>
            <Table aria-label="caption table">
              {/* <caption>Click here to Add Menu Modifier</caption> */}
              <TableHead>
                <TableRow>
                  <TableCell align="center">Addons</TableCell>
                  <TableCell align="center">Price</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" align="center">
                    <Autocomplete
                      sx={{
                        "@media (max-width: 600px)": {
                          padding: 0,
                          minWidth: 200,
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          padding: 0,
                          minWidth: 250,
                        },
                        "@media (min-width: 970px)": {
                          padding: 0,
                          minWidth: 250,
                        },
                      }}
                      options={addons?.data || []}
                      fullWidth
                      size="small"
                      onChange={(event, newValue) => {
                        setModifierId(newValue?.id);
                        setModifierName(newValue?.label);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select modfiers"
                          size="small"
                          sx={styleTextField}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      size="small"
                      sx={{
                        "@media (max-width: 600px)": {
                          padding: 0,
                          minWidth: 50,
                          "& .MuiInputBase-input": {
                            height: "40px", // Adjust the height value as per your requirement
                          },
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          padding: 0,
                          minWidth: 100,
                          "& .MuiInputBase-input": {
                            height: "50px", // Adjust the height value as per your requirement
                          },
                        },
                        "@media (min-width: 970px)": {
                          padding: 0,
                          minWidth: 150,
                          "& .MuiInputBase-input": {
                            height: "50px", // Adjust the height value as per your requirement
                          },
                        },
                      }}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={modifierPrice}
                      onChange={(event) => setModifierPrice(event.target.value)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <FormControl
                      sx={{
                        "@media (max-width: 600px)": {
                          // m: 1,
                          minWidth: 100,
                          "& .MuiInputBase-input": {
                            height: "40px", // Adjust the height value as per your requirement
                          },
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          // m: 1,
                          minWidth: 100,
                          "& .MuiInputBase-input": {
                            height: "50px", // Adjust the height value as per your requirement
                          },
                        },
                        "@media (min-width: 970px)": {
                          // m: 1,
                          minWidth: 150,
                          "& .MuiInputBase-input": {
                            height: "50px", // Adjust the height value as per your requirement
                          },
                        },
                      }}
                    >
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={modifierType}
                        onChange={handleChange}
                        autoWidth
                        label="Type"
                      >
                        <MenuItem value="single">single</MenuItem>
                        <MenuItem value="multiple">multiple</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => {
                        if (modifierId && modifierPrice && modifierType) {
                          setModifierData([
                            ...modifierData,
                            {
                              modifierId,
                              price: parseInt(modifierPrice),
                              type: modifierType,
                              name: modifierName,
                            },
                          ]);
                          // setModifierId("");
                          setModifierPrice(0);
                          setModifierType("");
                          setModifierName("");
                        }
                      }}
                    >
                      <AddCircleIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
                {/* {productsModifier?.data?.map((data, index) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell component="th" scope="row" align="center">
                          <TextField
                            sx={{ minWidth: 100 }}
                            value={data?.getModifiers?.name?.en}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            sx={{ minWidth: 100 }}
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={data?.price}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            sx={{ minWidth: 100 }}
                            value={data?.type}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton onClick={() => deleteOneModifier(data)}>
                            <CancelIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })} */}
                {modifierData?.map((data, index) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell component="th" scope="row" align="center">
                          <TextField
                            sx={styleTextField}
                            value={data?.getModifiers?.name?.en || data?.name}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField
                            sx={styleTextField}
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={data?.price}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <TextField sx={styleTextField} value={data?.type} />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => {
                              const newArr = [
                                ...modifierData.slice(0, index),
                                ...modifierData.slice(index + 1),
                              ];
                              // console.log("newArr:", newArr);

                              setModifierData(newArr);
                            }}
                          >
                            <CancelIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          {!loading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => setOpen(false)}
              style={{ fontFamily: "outfit" }}
            >
              Close
            </Button>
          ) : (
            ""
          )}
          {loading ? (
            <LoadingButton
              size="small"
              color="secondary"
              // onClick={handleClick}
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              <span>Save</span>
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              onClick={handleSubmit(addNewProduct)}
              style={{ fontFamily: "outfit" }}
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </div>
  );
};

export default AddProduct;

const styleTextField = {
  "@media (max-width: 600px)": {
    "& .MuiInputBase-input": {
      height: "40px", // Adjust the height value as per your requirement
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    "& .MuiInputBase-input": {
      height: "50px", // Adjust the height value as per your requirement
    },
  },
  "@media (min-width: 970px)": {
    "& .MuiInputBase-input": {
      height: "50px", // Adjust the height value as per your requirement
    },
  },
};
