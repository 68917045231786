import React, { useState, useEffect } from "react";
import "../../css/register.css";
import {
  useStripeProducts,
  useCurrentProvider,
} from "../../Common/Hooks/queryCalls";
import { formatCurrency } from "../../Services/functions";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useSelector } from "react-redux";
import { app, cancelSubscription,generalLogin} from "../../Services/dbService";
import envConfig from "../../env/env.json";
const appearance = { theme: "stripe" };


const StripeWindow = () => {
  const [stripePublishableKey, setStripePublishableKey] = useState("");
  const stripePromise = loadStripe(stripePublishableKey);
  const customDataUser = useSelector((x) => x.auth.customData);
  // const stripe = useStripe();
  const [providerSubscription, setProviderSubscription] = useState({});
  const [providerStripeAccount, setProviderStripeAccount] = useState(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isStripeAccountLoading, setIsStripeAccountLoading] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState({});
  const [clientSecret, setClientSecret] = useState("");
  const {
    data: provider,
    isLoading: isProviderLoading,
    refetch,
  } = useCurrentProvider();
  const { data: subscriptionsList, isLoading: isLoadingSubscriptionsList } =
    useStripeProducts();

  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] =
    useState("");
  const [authenticationCode, setAuthenticationCode] = useState("");
  const [stateValue, setStateValue] = useState("");

  const { type, status } = useParams();
  const searchParams = new URLSearchParams(window.location.search); //
  // console.log("params:", type, status);
  // console.log("searchParams:", searchParams);

  // useEffect(() => {
  //   // refetch();
  //   // console.log("app.currentUser::", customDataUser);
  // }, []);

  // console.log("customDataUser", customDataUser);

  useEffect(() => {
    stripeKey();
  }, []);

  async function stripeKey() {
    const gUser = await generalLogin();
    const { publishableKey } = await gUser?.callFunction("stripeKey");
    setStripePublishableKey(publishableKey);
    //console.log("stripePublishableKey:", stripePublishableKey);
    //console.log("stripeKey:", publishableKey);
  }

  useEffect(() => {
    if (searchParams.get("redirect_status")) {
      setPaymentStatus(searchParams.get("redirect_status"));
    }
    if (searchParams.get("payment_intent")) {
      setPaymentIntentId(searchParams.get("payment_intent"));
    }
    if (searchParams.get("payment_intent_client_secret")) {
      setPaymentIntentClientSecret(
        searchParams.get("payment_intent_client_secret")
      );
    }
    if (searchParams.get("code")) {
      setAuthenticationCode(searchParams.get("code"));
    }
    if (searchParams.get("state")) {
      setStateValue(searchParams.get("state"));
    }
  }, [searchParams]);

  useEffect(() => {
    if (subscriptionsList && provider) {
      switch (status) {
        case "initialize":
        case "renew":
          validateIntentAndInitializeStripePayment();
          break;
        case "create-redirect":
          manageStripeAccountRedirect();
          break;
        case "refresh":
          goToStripeOnBoarding();
          break;
        case "return":
          refreshStripeAccount();
          break;
        case "confirm":
          //console.log("HERE GOT CONFIRM");
          // Check the status in Stripe component and Redirect based on that
          break;

        default:
          break;
      }
    }
  }, [provider, subscriptionsList]);

  useEffect(() => {
    if (provider) {
      if (provider.subscription) {
        // console.log("provider.subscription", provider.subscription);
        if (provider.subscription.status !== "incomplete_expired") {
          setSelectedSubscription(provider.subscription.plan);
          setProviderSubscription(provider.subscription);
          if (
            provider.subscription.latest_invoice &&
            provider.subscription.latest_invoice.payment_intent &&
            provider.subscription.latest_invoice.payment_intent.client_secret
          ) {
            setClientSecret(
              provider.subscription.latest_invoice.payment_intent.client_secret
            );
          }
        }
      }
      if (provider.stripeAccount) {
        setProviderStripeAccount(provider.stripeAccount);
      }
    }
  }, [provider]);

  const changeSubscription = async (item) => {
    // setSelectedSubscription(item);
    setClientSecret(null);
    if (provider && provider.subscription) {
      // if(provider.subscription.status === 'incomplete_expired'){
      const result = await cancelSubscription({
        subscriptionId: provider?.subscription?.id,
      });
      return createProviderSubscription(item.id);
      // }
    } else {
      return createProviderSubscription(item.id);
    }
  };

  const createProviderSubscription = async (subscriptionId) => {
    if (!subscriptionId && subscriptionsList) {
      subscriptionsList.forEach((subscription) => {
        if (subscription.recurring.interval === "month") {
          subscriptionId = subscription.id;
        }
      });
    }
    const result = await app.currentUser.callFunction("provider", {
      functionName: "createProviderSubscription",
      params: {
        providerId: `${provider._id}`,
        customerId: provider.stripeId,
        priceId: subscriptionId,
      },
    });
    if (result) {
      setProviderSubscription(result);
      if (result.plan) {
        setSelectedSubscription(result.plan);
      }
      setClientSecret(null);
      if (
        result.latest_invoice &&
        result.latest_invoice.payment_intent &&
        result.latest_invoice.payment_intent.client_secret
      ) {
        setClientSecret(result.latest_invoice.payment_intent.client_secret);
      }
      return true;
    }
  };

  const validateIntentAndInitializeStripePayment = async () => {
    await refetch();
    if (
      provider &&
      provider.subscription &&
      provider.subscription.status === "active"
    ) {
      window.location.href = `${window.location.origin}/page-subscription/account`;
    }
    if (
      !selectedSubscription ||
      provider?.subscription?.status === "incomplete_expired"
    ) {
      await createProviderSubscription();
    }
  };

  const loadingDiv = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          height: "100%",
          width: "100%",
          background: "rgba(0,0,0,0.5)",
        }}
      >
        <p>Loading</p>
      </div>
    );
  };

  const getStripeId = () => {
    if (provider && providerStripeAccount) {
      if (providerStripeAccount.id) {
        return providerStripeAccount.id;
      }
      if (providerStripeAccount.stripe_user_id) {
        return providerStripeAccount.stripe_user_id;
      }
    }
  };

  const goToAppDashboard = () => {
    window.location.href = `${window.location.origin}/`;
  };

  const goToStripeDashboard = async () => {
    // console.log(providerStripeAccount);
    setIsStripeAccountLoading(true);
    const result = await app.currentUser.callFunction("stripeServices", {
      functionName: "createAccountLoginLink",
      params: {
        account: getStripeId(),
      },
    });
    setIsStripeAccountLoading(false);
    if (result) {
      window.location.href = result.url;
    }
  };

  const goToStripeOnBoarding = async () => {
    // console.log(providerStripeAccount);
    setIsStripeAccountLoading(true);
    const result = await app.currentUser.callFunction("stripeServices", {
      functionName: "createAccountLink",
      params: {
        account: getStripeId(),
        refresh_url: `${window.location.origin}/page-subscription/account/refresh`,
        return_url: `${window.location.origin}/page-subscription/account/return`,
      },
    });
    setIsStripeAccountLoading(false);
    if (result) {
      window.location.href = result.url;
    }
  };

  const createStripeAccount = async () => {
    setIsStripeAccountLoading(true);
    const result = await app.currentUser.callFunction("provider", {
      functionName: "createProviderStripeAccount",
      params: {
        providerId: `${provider._id}`,
      },
    });
    // console.log(result);
    setProviderStripeAccount(result);
    setIsStripeAccountLoading(false);
  };

  const manageStripeAccountRedirect = async () => {
    if (authenticationCode && stateValue === `${provider._id}`) {
      setIsStripeAccountLoading(true);
      const result = await app.currentUser.callFunction("provider", {
        functionName: "callbackProviderStripeAccount",
        params: {
          providerId: `${provider._id}`,
          authenticationCode,
        },
      });
      if (result && result.stripe_user_id) {
        setProviderStripeAccount(result);
      }
      setIsStripeAccountLoading(false);
    }
  };

  const refreshStripeAccount = async () => {
    setIsStripeAccountLoading(true);
    const result = await app.currentUser.callFunction("provider", {
      functionName: "refreshAccount",
      params: {
        providerId: `${provider._id}`,
        stripeId: getStripeId(),
      },
    });
    if (result) {
      setProviderStripeAccount(result);
    }
    setIsStripeAccountLoading(false);
  };

  return (
    <div className="subscription-main-div">
      {type === "payment" ? (
        isProviderLoading && isLoadingSubscriptionsList ? (
          loadingDiv()
        ) : (
          <div className="subscription-div">
            {isPaymentLoading ? loadingDiv() : null}
            <div className="subscription-plan-div">
              <h5>Select one of our plans to get started</h5>
              <div className="plan-div">
                {subscriptionsList?.map((item, index) => {
                  return (
                    <div className="sub-plan" key={index}>
                      <h4 className="plan-name">{item?.nickname}</h4>
                      <h3 className="plan-amt">{`${formatCurrency(
                        item?.unit_amount / 100
                      )}/${item?.recurring?.interval}`}</h3>
                      <h6 className="plan-des">
                        {` you will be billed ${formatCurrency(
                          item?.unit_amount / 100
                        )}  per ${item?.recurring?.interval}`}
                      </h6>{" "}
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor:
                            selectedSubscription?.id === item.id
                              ? "#037ffc"
                              : "#e3e1ed",
                          color:
                            selectedSubscription?.id === item.id
                              ? "white"
                              : "black",
                        }}
                        onClick={() => changeSubscription(item)}
                      >
                        {selectedSubscription?.id === item.id
                          ? "Selected"
                          : "Select"}
                      </Button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="checkoutForm">
              {clientSecret && stripePromise && appearance ? (
                <div
                  className="payment_div"
                  style={{
                    margin: "10px 0 0 0",
                    // padding: "10px",
                    borderRadius: "5px",
                    // border: `1px solid #a9bcb0`,
                  }}
                >
                  <Elements
                    options={{ clientSecret, appearance }}
                    stripe={stripePromise}
                  >
                    <CheckoutForm setIsPaymentLoading={setIsPaymentLoading} />
                  </Elements>
                </div>
              ) : null}
            </div>
          </div>
        )
      ) : (
        <div className="subscription-div">
          <div className="subscription-plan-div">
            <h5>Select one of options to continue</h5>
            <div className="plan-div">
              <div className="sub-plan">
                {/* <h4 className="plan-name">Dashboard</h4> */}
                <h3 className="plan-amt">Dashboard</h3>
                <h6 className="plan-des">
                  Go to Dashboard to View and Manage your profile, branches,
                  items, categories, reviews and orders
                </h6>{" "}
                <Button
                  variant="contained"
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#037ffc",
                      color: "white",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#037ffc",
                      color: "white",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#037ffc",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    goToAppDashboard();
                  }}
                >
                  Go To Dashboard
                </Button>
              </div>
              {providerStripeAccount ? (
                providerStripeAccount.details_submitted &&
                providerStripeAccount.charges_enabled ? (
                  <div className="sub-plan">
                    <h3 className="plan-amt">Stripe Dashboard</h3>
                    <h6 className="plan-des">
                      Go to Stripe dashboard to view payment details
                    </h6>{" "}
                    <Button
                      variant="contained"
                      sx={{
                        "@media (max-width: 600px)": {
                          fontSize: "0.6rem",
                          backgroundColor: "#037ffc",
                          color: "white",
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          fontSize: "0.8rem",
                          backgroundColor: "#037ffc",
                          color: "white",
                        },
                        "@media (min-width: 970px)": {
                          fontSize: "0.8rem",
                          backgroundColor: "#037ffc",
                          color: "white",
                        },
                      }}
                      onClick={() => {
                        goToStripeDashboard();
                      }}
                    >
                      {isStripeAccountLoading ? (
                        <>
                          <div className="spinner" id="spinner"></div>
                          <span>Please Wait</span>
                        </>
                      ) : (
                        "Go To Stripe Dashboard"
                      )}
                    </Button>
                  </div>
                ) : (
                  <div className="sub-plan">
                    <h3 className="plan-amt">Stripe OnBoarding</h3>
                    <h6 className="plan-des">
                      Go to Stripe OnBoarding to complete profile
                    </h6>{" "}
                    <Button
                      variant="contained"
                      sx={{
                        "@media (max-width: 600px)": {
                          fontSize: "0.6rem",
                          backgroundColor: "#037ffc",
                          color: "white",
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          fontSize: "0.8rem",
                          backgroundColor: "#037ffc",
                          color: "white",
                        },
                        "@media (min-width: 970px)": {
                          fontSize: "0.8rem",
                          backgroundColor: "#037ffc",
                          color: "white",
                        },
                      }}
                      onClick={() => {
                        goToStripeOnBoarding();
                      }}
                    >
                      {isStripeAccountLoading ? (
                        <>
                          <div className="spinner" id="spinner"></div>
                          <span>Please Wait</span>
                        </>
                      ) : (
                        "Go To Stripe OnBoarding"
                      )}
                    </Button>
                  </div>
                )
              ) : (
                <div className="sub-plan">
                  {/* <h4 className="plan-name">Stripe Account</h4> */}
                  <h3 className="plan-amt">Stripe Account</h3>
                  <h6 className="plan-des">
                    Go to Stripe to complete your payment profile in order to
                    receive the payment to your account
                  </h6>{" "}
                  <Button
                    variant="contained"
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "0.6rem",
                        backgroundColor: "#037ffc",
                        color: "white",
                      },
                      "@media (min-width: 601px) and (max-width: 960px)": {
                        fontSize: "0.8rem",
                        backgroundColor: "#037ffc",
                        color: "white",
                      },
                      "@media (min-width: 970px)": {
                        fontSize: "0.8rem",
                        backgroundColor: "#037ffc",
                        color: "white",
                      },
                    }}
                    onClick={() => {
                      createStripeAccount();
                    }}
                  >
                    {isStripeAccountLoading ? (
                      <>
                        <div className="spinner" id="spinner"></div>
                        <span>Please Wait</span>
                      </>
                    ) : (
                      "Create Stripe Account"
                    )}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StripeWindow;
