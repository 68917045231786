import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Typography, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "../../../css/addons.css";
import { addAddons, updateAddons } from "../../../Services/dbService";

const AddAddon = ({
  providerId,
  setOpen,
  edit,
  specificAddon = null,
  refetch,
}) => {
  const [loading, setLoading] = React.useState(false);
  const validationSchema = Yup.object().shape({
    englishName: Yup.string().required(" Name is required"),
    arabicName: Yup.string().required("اسم  is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  async function addNewAddons(data) {
    if (edit) {
      // console.log("editAddon ", data);
      setLoading(true);
      const result = await updateAddons({
        providerId,
        item: specificAddon,
        data,
      });
      // console.log("Editresult:", result);
      if (result) {
        setLoading(false);
        setOpen(false);
        refetch();
      }
    } else {
      //console.log("addAddon ", data);
      setLoading(true);
      const result = await addAddons({ providerId, data });
    //  console.log("Addresult:", result);
      if (result) {
        setLoading(false);
        setOpen(false);
        refetch();
      }
    }
  }

  useEffect(() => {
    setValue("englishName", specificAddon?.name?.en);
    setValue("arabicName", specificAddon?.name?.ar);
  }, [specificAddon]);
  return (
    <div>
      <h4>Add New Addons</h4>{" "}
      <form>
        <div className="Addons-text-field">
          <div className="Addons-text-field-row">
            <TextField
              sx={styleTextField}
              fullWidth
              label="Name*"
              id="fullWidth"
              size="small"
              {...register("englishName", { required: true })}
              error={errors.englishName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.englishName && errors.englishName?.message
                ? errors.englishName?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="Addons-text-field-row">
            {" "}
            <TextField
              sx={styleTextField}
              fullWidth
              label="اسم*"
              id="fullWidth"
              size="small"
              {...register("arabicName", { required: true })}
              error={errors.arabicName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.arabicName && errors.arabicName?.message
                ? errors.arabicName?.message.toString()
                : null}
            </Typography>
          </div>
        </div>

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
          }}
        
        >
          {!loading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => setOpen(false)}
              style={{ fontFamily: "outfit" }}
            >
              Close
            </Button>
          ) : (
            ""
          )}
          {loading ? (
            <LoadingButton
              size="small"
              color="secondary"
              // onClick={handleClick}
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              <span>Save</span>
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              onClick={handleSubmit(addNewAddons)}
              style={{ fontFamily: "outfit" }}
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </div>
  );
};

export default AddAddon;
const styleTextField = {
  "@media (max-width: 600px)": {
    "& .MuiInputBase-input": {
      height: "40px", // Adjust the height value as per your requirement
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    "& .MuiInputBase-input": {
      height: "50px", // Adjust the height value as per your requirement
    },
  },
  "@media (min-width: 970px)": {
    "& .MuiInputBase-input": {
      height: "50px", // Adjust the height value as per your requirement
    },
  },
};
