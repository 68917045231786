import * as Realm from "realm-web";
import envConfig from "../env/env.json";
export const app = new Realm.App({ id: envConfig.MONGODB_APP_ID });
export const gApp = new Realm.App({ id: envConfig.MONGODB_APP_ID_GENERAL });

export const {
  BSON: { ObjectId },
} = Realm;

export const getDBInstance = (appInstance) => {
  return appInstance.currentUser
    .mongoClient(envConfig.MONGODB_CLIENT)
    .db(envConfig.MONGODB_DB);
};

export const generalLogin = async () => {
  const gUser = await gApp.logIn(
    Realm.Credentials.apiKey(envConfig.MONGODB_APP_TOKEN_GENERAL)
  );
  return gUser;
};

export const getCurrentProvider = async ({ providerId }) => {
  // console.log("got value", providerId);
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId) {
    return;
  }

  return await getDBInstance(app)
    .collection("providers")
    .findOne({ _id: providerId });
};

export const getProvider = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  const result = await getDBInstance(app)
    .collection("providers")
    .findOne({ _id: providerId });

  return result;
};

export const updateProvider = async ({
  providerId,
  data,
  resIcon,
  resBanner,
}) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId || !data) {
    return;
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  const result = await getDBInstance(app)
    .collection("providers")
    .updateOne(
      {
        _id: providerId,
      },
      {
        $set: {
          email: data.email,
          number: data.number,
          username: data.username,
          description: {
            en: data.englishBio,
            ar: data.arabicBio,
          },
          name: {
            en: data.englishName,
            ar: data.arabicName,
          },
          primaryColor: data.primaryColor,
          secondaryColor: data.secondaryColor,
          cartTextColor: data.cartTextColor,
          headerBackgroundColor: data.headerBackgroundColor,
          pageBackgroundColor: data.pageBackgroundColor,
          primaryTextColor: data.primaryTextColor,
          secondaryTextColor: data.secondaryTextColor,
          borderColor: data.borderColor,
          icon: resIcon,
          mainImage: resBanner,
        },
      }
    );

  return result;
};

export const getBranches = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
  };

  const result = await getDBInstance(app)
    .collection("branches")
    .find({ providerId: providerId }, queryOptions);

  return result;
};

export const addBranch = async ({
  providerId,
  data,
  geoCode,
  cityId,
  showNumber,
}) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId || !data) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  if (cityId && typeof cityId === "string") {
    cityId = new ObjectId(cityId);
  }

  // console.log("geoCode::", typeof geoCode.lng);

  const metadata = {
    createdBy: null,
    isActive: true,
    createdAt: new Date(),
    modifiedBy: null,
  };

  const result = await getDBInstance(app)
    .collection("branches")
    .insertOne({
      metadata: metadata,
      name: {
        ar: data.arabicName,
        en: data.englishName,
      },
      email: data.email,
      number: data.number,
      username: data.username,
      prefix: data.prefix.toUpperCase(),
      address: data.branchAddress,
      city: cityId ? cityId : null,
      providerId: providerId,
      isApproved: true,
      geo: {
        coordinates: [geoCode.lng, geoCode.lat],
        type: "Point",
      },
      showNumber: showNumber,
    });
  if (result && result.insertedId) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranch",
      params: { providerId, branchId: result.insertedId },
    });
    return { result, appServiceFun };
  }
};

export const updateBranch = async ({
  providerId,
  itemId,
  data,
  geoCode,
  cityId,
  showNumber,
}) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId || !itemId || !data) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  if (typeof itemId === "string") {
    itemId = new ObjectId(itemId);
  }

  if (cityId && typeof cityId === "string") {
    cityId = new ObjectId(cityId);
  }

  // console.log("geoCode::", geoCode);

  const result = await getDBInstance(app)
    .collection("branches")
    .updateOne(
      {
        _id: itemId,
      },
      {
        $set: {
          name: {
            ar: data.arabicName,
            en: data.englishName,
          },
          email: data.email,
          number: data.number,
          username: data.username,
          prefix: data.prefix.toUpperCase(),
          address: data.branchAddress,
          city: cityId ? cityId : null,
          geo: {
            coordinates: [geoCode.lng, geoCode.lat],
            type: "Point",
          },
          showNumber: showNumber,
        },
      }
    );

  if (result) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranch",
      params: { providerId, branchId: itemId },
    });
    return { result, appServiceFun };
  }
};

export const getBranchModifiers = async ({ branchId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!branchId) {
    return;
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId);
  }

  const result = await getDBInstance(app)
    .collection("branchmodifiers")
    .aggregate([
      {
        $match: {
          branchId: branchId,
        },
      },
      {
        $lookup: {
          from: "modifiers",
          localField: "modifierId",
          foreignField: "_id",
          as: "getBranchModifiers",
        },
      },
      {
        $unwind: "$getBranchModifiers",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ]);

  return result;
};

export const getBranchProducts = async ({ branchId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!branchId) {
    return;
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId);
  }
  // console.log("branchProduct::", branchId);
  //5f68380a14721c6445ed45ea
  const result = await getDBInstance(app)
    .collection("branchmenus")
    .aggregate([
      {
        $match: {
          branchId: branchId,
        },
      },
      {
        $lookup: {
          from: "menus",
          localField: "menuId",
          foreignField: "_id",
          as: "getBranchProducts",
        },
      },
      {
        $unwind: "$getBranchProducts",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ]);

  return result;
};

export const deleteBranch = async ({ providerId, branchId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId || !branchId) {
    return;
  }
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId);
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  const result = await getDBInstance(app)
    .collection("branches")
    .deleteOne({ _id: branchId });

  if (result) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranch",
      params: { providerId, branchId },
    });
    return { result, appServiceFun };
  }
};

export const getModifiers = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }
  const queryOptions = {
    // Sort the data by a field in descending order to get the latest data at the top
    sort: { _id: -1 },
  };

  const result = await getDBInstance(app)
    .collection("modifiers")
    .find({ providerId: providerId }, queryOptions);

  return result;
};

export const addAddons = async ({ providerId, data }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId || !data) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  const metadata = {
    // createdBy: entityId,
    //modifiedAt:'',
    //modifiedBy:'',
    isActive: true,
    createdAt: new Date(),
  };

  const result = await getDBInstance(app)
    .collection("modifiers")
    .insertOne({
      metadata: metadata,
      name: {
        ar: data.arabicName,
        en: data.englishName,
      },
      providerId: providerId,
      isApproved: true,
    });

  if (result) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranches",
      params: { providerId },
    });
    return { result, appServiceFun };
  }
};

export const updateAddons = async ({ providerId, item, data }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId || !item || !data) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  const result = await getDBInstance(app)
    .collection("modifiers")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          name: {
            ar: data.arabicName,
            en: data.englishName,
          },
        },
      }
    );

  if (result) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranches",
      params: { providerId },
    });
    return { result, appServiceFun };
  }
};

export const deleteAddons = async ({ providerId, modifierId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId || !modifierId) {
    return;
  }
  if (typeof modifierId === "string") {
    modifierId = new ObjectId(modifierId);
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  const result = await getDBInstance(app)
    .collection("modifiers")
    .deleteOne({ _id: modifierId });
  const branchmodifiersResult = await getDBInstance(app)
    .collection("branchmodifiers")
    .deleteMany({ modifierId });

  if (result) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranches",
      params: { providerId },
    });
    return { result, appServiceFun, branchmodifiersResult };
  }
};

export const getCategorySearch = async ({ search }) => {
  if (!app || !app.currentUser) {
    return;
  }

  const result = await getDBInstance(app)
    .collection("categories")
    .aggregate([
      {
        $match: {
          $or: [
            { "name.en": { $regex: `^${search}`, $options: "i" } },
            { "name.ar": { $regex: `^${search}`, $options: "i" } },
          ],
          "metadata.isActive": true,
          type: { $in: ["home-business"] },
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: {
              $concat: ["$name.en", "(", "$name.ar", ")"],
            },
          },
        },
      },
    ]);
  return result;
};

export const getCategorySetting = async ({ categoryId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!categoryId) {
    return;
  }
  if (typeof categoryId === "string") {
    categoryId = new ObjectId(categoryId);
  }

  const result = await getDBInstance(app)
    .collection("categories")
    .aggregate([
      {
        $match: {
          _id: categoryId,
          // "metadata.isActive": true,
          // type: { $in: ["home-business", "food"] }
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: {
              $concat: ["$name.en", "(", "$name.ar", ")"],
            },
          },
        },
      },
    ]);
  return result[0];
};

export const getSubCategories = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  const result = await getDBInstance(app)
    .collection("subcategories")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $lookup: {
          from: "categories",
          localField: "categoryId",
          foreignField: "_id",
          as: "getCategories",
        },
      },
      {
        $unwind: "$getCategories",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ]);

  return result;
};

export const addSubCategory = async ({ providerId, data, categoryId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId || !data || !categoryId) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  if (typeof categoryId === "string") {
    categoryId = new ObjectId(categoryId);
  }

  const metadata = {
    // createdBy: entityId,
    //modifiedAt:'',
    //modifiedBy:'',
    isActive: true,
    createdAt: new Date(),
  };

  const result = await getDBInstance(app)
    .collection("subcategories")
    .insertOne({
      metadata: metadata,
      name: {
        ar: data.arabicName,
        en: data.englishName,
      },
      providerId: providerId,
      isApproved: true,
      categoryId: categoryId,
    });

  return result;
};

export const updateSubCategory = async ({ item, data, categoryId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!item || !data || !categoryId) {
    return;
  }
  if (typeof categoryId === "string") {
    categoryId = new ObjectId(categoryId);
  }

  const result = await getDBInstance(app)
    .collection("subcategories")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          name: {
            ar: data.arabicName,
            en: data.englishName,
          },
          categoryId: categoryId,
        },
      }
    );

  return result;
};

export const deleteSubCategory = async ({ subCategoryId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!subCategoryId) {
    return;
  }
  if (typeof subCategoryId === "string") {
    subCategoryId = new ObjectId(subCategoryId);
  }

  const result = await getDBInstance(app)
    .collection("subcategories")
    .deleteOne({ _id: subCategoryId });

  return result;
};

export const getProducts = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  const result = await getDBInstance(app)
    .collection("menus")
    // .find({ providerId: providerId });
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $lookup: {
          from: "categories",
          localField: "categoryId",
          foreignField: "_id",
          as: "getCategories",
        },
      },
      {
        $unwind: "$getCategories",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ]);

  return result;
};

export const getProductsModifiers = async ({ menuId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!menuId) {
    return;
  }
  if (typeof menuId === "string") {
    menuId = new ObjectId(menuId);
  }

  const result = await getDBInstance(app)
    .collection("menumodifiers")
    // .find({ menuId: menuId });
    .aggregate([
      {
        $match: {
          menuId: menuId,
        },
      },
      {
        $lookup: {
          from: "modifiers",
          localField: "modifierId",
          foreignField: "_id",
          as: "getModifiers",
        },
      },
      {
        $unwind: "$getModifiers",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ]);

  return result;
};

export const addProduct = async ({
  providerId,
  data,
  resIcon,
  categoryId,
  subCategoryId,
  modifierData,
  isActive,
}) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId || !data) {
    return;
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }
  if (categoryId && typeof categoryId === "string") {
    categoryId = new ObjectId(categoryId);
  }

  if (subCategoryId && typeof subCategoryId === "string") {
    subCategoryId = new ObjectId(subCategoryId);
  }
  const metadata = {
    createdBy: null,
    modifiedAt: null,
    modifiedBy: null,
    isActive: isActive,
    createdAt: new Date(),
  };
  let modifierResult;
  let appServiceFun;
  const result = await getDBInstance(app)
    .collection("menus")
    .insertOne({
      metadata: metadata,
      name: {
        ar: data.arabicName,
        en: data.englishName,
      },
      description: {
        ar: data.arabicDescription,
        en: data.englishDescription,
      },
      providerId: providerId,
      isApproved: true,
      mainImage: resIcon,
      price: data.price,
      salePrice: data.salePrice,
      maximumModifiers: data.maximumAddons,
      minimumModifiers: data.minimumAddons,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
    });

  if (result) {
    appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranches",
      params: { providerId },
    });
    // return { result, appServiceFun, menuModifier: modifierResult };
  }

  if (result?.insertedId && modifierData && modifierData.length !== 0) {
    // console.log("Addresult::", result);
    // console.log("insertedId::", result.insertedId);
    // console.log("modi::", modifierData);
    const newArray = modifierData.map((obj) => {
      return {
        ...obj, // spread operator to copy existing properties of each object
        menuId: result.insertedId, // add a new field and value to each object
        metadata: {
          isActive: true,
          createdAt: new Date(),
        },
      };
    });
    // console.log("newArrayModifier::", newArray);
    if (newArray) {
      const resultArray = newArray.map((obj) => {
        const { name, ...rest } = obj; // using object destructuring to remove the age field
        return rest;
      });
      // console.log("resultArray:", resultArray);
      if (resultArray) {
        modifierResult = await getDBInstance(app)
          .collection("menumodifiers")
          .insertMany(resultArray);
      }
    }

    return { result, appServiceFun, menuModifier: modifierResult };
  } else {
    return { result, appServiceFun, menuModifier: modifierResult };
  }

  // return { menu: result, menuModifier: modifierResult };
};

export const updateProduct = async ({
  providerId,
  item,
  data,
  resIcon,
  categoryId,
  subCategoryId,
  modifierData,
  isActive,
}) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId || !item || !data) {
    return;
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  if (categoryId && typeof categoryId === "string") {
    categoryId = new ObjectId(categoryId);
  }

  if (subCategoryId && typeof subCategoryId === "string") {
    subCategoryId = new ObjectId(subCategoryId);
  }

  let modifierResult;
  let menuModifierResult;
  let appServiceFun;

  const result = await getDBInstance(app)
    .collection("menus")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          "metadata.isActive": isActive,
          "metadata.modifiedAt": new Date(),
          name: {
            ar: data.arabicName,
            en: data.englishName,
          },
          description: {
            ar: data.arabicDescription,
            en: data.englishDescription,
          },
          mainImage: resIcon,
          price: data.price,
          salePrice: data.salePrice,
          maximumModifiers: data.maximumAddons,
          minimumModifiers: data.minimumAddons,
          categoryId: categoryId,
          subCategoryId: subCategoryId,
        },
      }
    );

  if (result) {
    appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranches",
      params: { providerId },
    });
  }

  if (result && modifierData && modifierData.length !== 0) {
    menuModifierResult = await getDBInstance(app)
      .collection("menumodifiers")
      .deleteMany({ menuId: item._id });

    if (menuModifierResult) {
      const newArray = modifierData.map((obj) => {
        return {
          ...obj, // spread operator to copy existing properties of each object
          menuId: item._id, // add a new field and value to each object
          metadata: {
            isActive: true,
            createdAt: new Date(),
          },
        };
      });
      // console.log("newArrayModifier::", newArray);

      if (newArray) {
        const resultArray = newArray.map((obj) => {
          const { getModifiers, name, ...rest } = obj; // using object destructuring to remove the age field
          return rest;
        });
        // console.log("resultArray:", resultArray);
        if (resultArray) {
          modifierResult = await getDBInstance(app)
            .collection("menumodifiers")
            .insertMany(resultArray);
        }
      }
    }
    return { result, appServiceFun, menuModifierResult, modifierResult };
  } else {
    return { result, appServiceFun, menuModifierResult, modifierResult };
  }

  // return { result, menuModifierResult, modifierResult };
};

export const deleteProduct = async ({ providerId, menuId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId || !menuId) {
    return;
  }
  if (typeof menuId === "string") {
    menuId = new ObjectId(menuId);
  }

  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  const menuResult = await getDBInstance(app)
    .collection("menus")
    .deleteOne({ _id: menuId });
  const menuModifierResult = await getDBInstance(app)
    .collection("menumodifiers")
    .deleteMany({ menuId });
  const branchMenuResult = await getDBInstance(app)
    .collection("branchmenus")
    .deleteMany({ menuId });

  if (menuResult) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranches",
      params: { providerId },
    });
    return { menuResult, menuModifierResult, appServiceFun, branchMenuResult };
  }

  // return { menuResult, menuModifierResult };
};

export const deleteProductModifier = async ({ modifierId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!modifierId) {
    return;
  }
  if (typeof modifierId === "string") {
    modifierId = new ObjectId(modifierId);
  }

  const result = await getDBInstance(app)
    .collection("menumodifiers")
    .deleteOne({ _id: modifierId });

  return result;
};

export const getProductCategorySearch = async ({ search }) => {
  if (!app || !app.currentUser) {
    return;
  }

  const result = await getDBInstance(app)
    .collection("categories")
    .aggregate([
      {
        $match: {
          $or: [
            { "name.en": { $regex: `^${search}`, $options: "i" } },
            { "name.ar": { $regex: `^${search}`, $options: "i" } },
          ],
          "metadata.isActive": true,
          type: { $in: ["home-business"] },
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: {
              $concat: ["$name.en", "(", "$name.ar", ")"],
            },
          },
        },
      },
    ]);
  // .aggregate([
  //   {
  //     $match: {
  //       $or: [
  //         { "name.en": { $regex: `^${search}`, $options: "i" } },
  //         { "name.ar": { $regex: `^${search}`, $options: "i" } },
  //       ],
  //       "metadata.isActive": true,
  //     },
  //   },
  //   {
  //     $lookup: {
  //       from: "categories",
  //       localField: "categoryId",
  //       foreignField: "_id",
  //       as: "getCategories",
  //     },
  //   },
  //   {
  //     $match: {
  //       $and: [
  //         { "getCategories.metadata.isActive": true },
  //         { "getCategories.type": "home-business" && "food" },
  //       ],
  //     },
  //   },
  //   {
  //     $unwind: "$getCategories",
  //   },
  //   {
  //     $replaceRoot: {
  //       newRoot: {
  //         subCategoryId: "$_id",
  //         categoryId:"$getCategories._id",
  //         label: {
  //           $concat: ["$getCategories.name.en", "(", "$getCategories.name.ar", ")"],
  //         },
  //       },
  //     },
  //   },
  // ]);
  return result;
};

export const getCompletedOrders = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: {
          providerId: providerId,
          status: { $in: ["completed"] },
          // paymentStatus: "Paid" || "completed",
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ]);

  return result;
};

export const getOngoingOrders = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: {
          providerId: providerId,
          status: { $nin: ["completed", "cancelled", "refunded"] },
          // paymentStatus: { $nin: ["Paid", "completed"] },
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ]);

  return result;
};

export const getOneOrderDetail = async ({ orderId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!orderId) {
    return;
  }
  if (typeof orderId === "string") {
    orderId = new ObjectId(orderId);
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: {
          _id: orderId,
        },
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
    ]);

  return result[0];
};

export const getSpecificSubcatogories = async ({ subId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!subId) {
    return;
  }
  if (typeof subId === "string") {
    subId = new ObjectId(subId);
  }

  const result = await getDBInstance(app)
    .collection("subcategories")
    // .find({ categoryId: subId });
    .aggregate([
      {
        $match: {
          categoryId: subId,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: {
              $concat: ["$name.en", "(", "$name.ar", ")"],
            },
          },
        },
      },
    ]);

  return result;
};

export const getSpecificSubcatogoriesSetting = async ({ subCategoryId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!subCategoryId) {
    return;
  }
  if (typeof subCategoryId === "string") {
    subCategoryId = new ObjectId(subCategoryId);
  }

  const result = await getDBInstance(app)
    .collection("subcategories")
    // .find({ categoryId: subCategoryId });
    .aggregate([
      {
        $match: {
          _id: subCategoryId,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: {
              $concat: ["$name.en", "(", "$name.ar", ")"],
            },
          },
        },
      },
    ]);

  return result[0];
};

export const getReviews = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId) {
    return;
  }
  if (typeof providerId !== "string") {
    providerId = providerId.toString();
  }

  const result = await getDBInstance(app)
    .collection("reviews")
    .aggregate([
      {
        $match: {
          "reviewedOn.entityId": providerId,
          "reviewedOn.entity": "provider",
        },
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ]);

  return result;
};

export const getAddons = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  const result = await getDBInstance(app)
    .collection("modifiers")
    // .find({ categoryId: providerId });
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: {
              $concat: ["$name.en", "(", "$name.ar", ")"],
            },
          },
        },
      },
    ]);

  return result;
};

export const registerDetails = async ({ data, selectedSubscription }) => {
  // if (!app || !app.currentUser) {
  //   const credentials = Realm.Credentials.anonymous();
  //   const test = await app.logIn(credentials);
  // }

  if (!data || !selectedSubscription) {
    return;
  }

  const credentials = Realm.Credentials.anonymous();
  const test = await app.logIn(credentials);

  // const reference = await app.currentUser.callFunction(
  //   "generateReference",
  //   "providers",
  //   "PDR-",
  //   "reference"
  // );

  if (test) {
    // const metadata = {
    //   createdBy: null,
    //   isActive: true,
    //   createdAt: new Date(),
    //   modifiedBy: null,
    // };

    const result = await app.currentUser.callFunction("registerProvider", {
      data,
      subscription: selectedSubscription,
    });

    if (app.currentUser) {
      app.currentUser.logOut();
    }
    // console.log("app services result:", result);
    return result;
  } else {
    // console.log("something wrong");
  }
};

export const getPrefixCheck = async ({ fieldValue }) => {
  if (!app || !app.currentUser) {
    return;
  }

  const result = await getDBInstance(app)
    .collection("branches")
    // .find({ categoryId: providerId });
    .aggregate([
      {
        $match: {
          $or: [
            { prefix: fieldValue.toUpperCase() },
            { number: fieldValue.toString() },
          ],
        },
      },
    ]);

  return result;
};

//Revenue
export const getBranchesSearch = async ({ providerId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }

  const result = await getDBInstance(app)
    .collection("branches")
    .aggregate([
      {
        $match: {
          providerId: providerId,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: {
              $concat: ["$name.en", "(", "$name.ar", ")"],
            },
          },
        },
      },
    ]);
  return result;
};

export const getOrdersBtwDates = async ({
  startDate,
  endDate,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!providerId) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }
  const query = {
    $and: [
      { "metadata.isActive": true },
      { status: "completed" },
      { providerId: providerId },
    ],
  };
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId);
  }
  if (branchId) {
    query.$and.push({ branchId: branchId });
  }
  if (startDate && endDate) {
    query.$and.push({
      "metadata.createdAt": { $gt: startDate },
    });
    query.$and.push({
      "metadata.createdAt": { $lt: endDate },
    });
  }

  // console.log("query:", query);

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },
      {
        $unwind: "$customer",
      },
      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ]);
  return result;
};

export const getOrdersThisyear = async ({
  startOfYear,
  endOfYear,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return;
  }
  // console.log(providerId, startOfYear, endOfYear);
  if (!providerId || !startOfYear || !endOfYear) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }
  const query = {
    $and: [
      {
        "metadata.createdAt": { $gt: startOfYear },
      },
      {
        "metadata.createdAt": { $lt: endOfYear },
      },
      { "metadata.isActive": true },
      { status: "completed" },
      { providerId: providerId },
    ],
  };
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId);
  }
  if (branchId) {
    query.$and.push({ branchId: branchId });
  }
  // if (typeof providerId === "string") {
  //   providerId = new ObjectId(providerId);
  // }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },

      {
        $unwind: "$customer",
      },

      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ]);
  return result;
};

export const getOrdersThisMonth = async ({
  startOfMonth,
  endOfMonth,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return;
  }
  // console.log(providerId, startOfMonth, endOfMonth);
  if (!providerId || !startOfMonth || !endOfMonth) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }
  const query = {
    $and: [
      {
        "metadata.createdAt": { $gt: startOfMonth },
      },
      {
        "metadata.createdAt": { $lt: endOfMonth },
      },
      { "metadata.isActive": true },
      { status: "completed" },
      { providerId: providerId },
    ],
  };
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId);
  }
  if (branchId) {
    query.$and.push({ branchId: branchId });
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },

      {
        $unwind: "$customer",
      },

      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ]);
  return result;
};

export const getOrdersThisWeek = async ({
  startOfWeek,
  endOfWeek,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return;
  }
  // console.log(providerId, startOfWeek, endOfWeek);
  if (!providerId || !startOfWeek || !endOfWeek) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }
  const query = {
    $and: [
      {
        "metadata.createdAt": { $gt: startOfWeek },
      },
      {
        "metadata.createdAt": { $lt: endOfWeek },
      },
      { "metadata.isActive": true },
      { status: "completed" },
      { providerId: providerId },
    ],
  };
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId);
  }
  if (branchId) {
    query.$and.push({ branchId: branchId });
  }
  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },

      {
        $unwind: "$customer",
      },

      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ]);
  return result;
};

export const getOrdersThisDay = async ({
  startTodays,
  endTodays,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return;
  }
  // console.log(providerId, startTodays, endTodays);
  if (!providerId || !startTodays || !endTodays) {
    return;
  }
  if (typeof providerId === "string") {
    providerId = new ObjectId(providerId);
  }
  const query = {
    $and: [
      {
        "metadata.createdAt": { $gt: startTodays },
      },
      {
        "metadata.createdAt": { $lt: endTodays },
      },
      { "metadata.isActive": true },
      { status: "completed" },
      { providerId: providerId },
    ],
  };
  if (typeof branchId === "string") {
    branchId = new ObjectId(branchId);
  }
  if (branchId) {
    query.$and.push({ branchId: branchId });
  }

  const result = await getDBInstance(app)
    .collection("orders")
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: "customers",
          localField: "customerId",
          foreignField: "_id",
          as: "customer",
        },
      },

      {
        $unwind: "$customer",
      },

      {
        $sort: {
          // date: -1,
          _id: -1,
        },
      },
    ]);
  return result;
};

export const getStripeProducts = async () => {
  if (!app || !app.currentUser) {
    const credentials = Realm.Credentials.anonymous();
    const test = await app.logIn(credentials);
  }
  const result = await app.currentUser.callFunction("stripeServices", {
    functionName: "getStripeProducts",
  });
  return result;
};

export const cancelSubscription = ({ subscriptionId }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!subscriptionId) {
    return;
  }

  // console.log("subscriptionId", subscriptionId);
  return app.currentUser.callFunction("stripeServices", {
    functionName: "cancelSubscription",
    params: { subscriptionId },
  });
};

export const OrderStatusUpdate = async ({ itemId, orderStatus, data }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!itemId || !orderStatus || !data) {
    return;
  }

  //console.log("order details");

  let result1;

  const gUser = await generalLogin();

  const params = {
    _id: itemId,
    orderStatus: orderStatus,
  };

  const result = await gUser?.callFunction("orders", {
    functionName: "orderStatusChange",
    params: params,
  });

  if (orderStatus == "ready") {
    result1 = await gUser?.callFunction("quickup", {
      functionName: "readyForCollection",
      params: {
        orderId: itemId.toString(),
        deliveryId: data.delivery.order.id,
      },
    });
  }
  // else if (orderStatus == "completed") {
  //   result1 = await gUser?.callFunction("quickup", {
  //     functionName: "downloadAWb",
  //     params: { deliveryId: data.delivery.order.id },
  //   });
  // }

  return { result, result1 };
};

export const DownloadAwb = async ({ data }) => {
  if (!app || !app.currentUser) {
    return;
  }

  const gUser = await generalLogin();

  // const result = await gUser?.callFunction("quickup", {
  //   functionName: "downloadAWb",
  //   params: { deliveryId: data.delivery.order.id },
  // });
  const { token, baseUrl } = await gUser?.callFunction("quickup", {
    functionName: "downloadAWb",
    params: {},
  });

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(
    `${baseUrl}/order_label/${data.delivery.order.id}`,
    requestOptions
  );
};

export const acceptOrder = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  let result1;

  const gUser = await generalLogin();

  const result = await gUser?.callFunction("orders", {
    functionName: "acceptOrder",
    params: { id },
  });

  result1 = await gUser?.callFunction("quickup", {
    functionName: "createOrder",
    params: { orderId: id },
  });
  return { result, result1 };
};

export const getBranchCity = async () => {
  if (!app || !app.currentUser) {
    return;
  }

  const result = await getDBInstance(app)
    .collection("cities")
    .aggregate([
      {
        $match: {
          // $or: [
          //   { "name.en": { $regex: `^${search}`, $options: "i" } },
          //   { "name.ar": { $regex: `^${search}`, $options: "i" } },
          // ],
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            id: "$_id",
            label: "$name",
          },
        },
      },
    ]);
  return result;
};

export const getBranchCitySetting = async ({ cityId }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!cityId) {
    return;
  }
  if (typeof cityId === "string") {
    cityId = new ObjectId(cityId);
  }

  const result = await getDBInstance(app)
    .collection("cities")
    .aggregate([
      {
        $match: {
          _id: cityId,
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            // id: "$_id",
            label: "$name",
          },
        },
      },
    ]);
  return result[0];
};

export const updateIsActive = async ({
  item,
  isActive,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!item) {
    return;
  }

  // console.log("itemid:", item._id.toString());

  const result = await getDBInstance(app)
    .collection("branchmenus")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          "metadata.isActive": isActive,
        },
      }
    );

  if (result) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranch",
      params: { providerId, branchId },
    });
    return { result, appServiceFun };
  }
};

export const updateIsStock = async ({
  item,
  isStock,
  providerId,
  branchId,
}) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!item) {
    return;
  }

  // console.log("itemid:", item._id.toString());

  const result = await getDBInstance(app)
    .collection("branchmenus")
    .updateOne(
      {
        _id: item._id,
      },
      {
        $set: {
          inStock: isStock,
        },
      }
    );

  if (result) {
    const appServiceFun = await app.currentUser.callFunction("Tools", {
      functionName: "syncProviderBranch",
      params: { providerId, branchId },
    });
    return { result, appServiceFun };
  }
};
