import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { app } from "./dbService";

const Protected = ({ children }) => {
  const location = useLocation();
  const authType = useSelector((x) => x.auth.type);
  if (!app.currentUser || (app.currentUser && app.currentUser.providerType !== 'local-userpass'))
    return (
      <>
        <Redirect to="/login" state={{ from: location }} />
      </>
    );
  return children;
};

export default Protected;
