import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../Stores/auth.slice";
import { app } from "../../Services/dbService";
import { Button } from "@mui/material";
import Lottie from "lottie-react";
import UpdatePassword from "../../lottie/loading-and-done.json";

//import { Link } from "react-router-dom";
const ForgotPassword = ({ history }) => {
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [result, SetResult] = useState({});
  const dispatch = useDispatch();
  const passwordsMatch = password === confirmPassword;
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const tokenId = params.get("tokenId");
  // console.log(token, tokenId);
  const onSubmit = async (e) => {
    e.preventDefault();
    const result = await dispatch(
      authActions.resetPasswordConfirmEmail({ password, token, tokenId })
    );
    // console.log("Confirmed :", result);
    SetResult(result);
    return result;
  };
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div
              className="authincation-content"
              style={{
                marginTop: "50%",
              }}
            >
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h4 className="text-center mb-4">Forgot Password</h4>
                    <form>
                      <div className="form-group">
                        <label>
                          <strong>New Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Enter New Password Here"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <strong>Confirm Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          placeholder="Confirm Password"
                        />
                      </div>
                      {!passwordsMatch && password && confirmPassword ? (
                        <div className="text-center">
                          <h6 style={{ color: "red" }}>
                            Passwords do not match{" "}
                          </h6>
                        </div>
                      ) : (
                        ""
                      )}
                      {!token && !tokenId ? (
                        ""
                      ) : (
                        <div className="text-center">
                          {/* <input
                            type="submit"
                            value="Confirm Password"
                            className="btn btn-primary btn-block"
                          /> */}
                          {result?.type == "auth/resetConfirm/fulfilled" ? (
                            ""
                          ) : (
                            <Button
                              variant="outlined"
                              onClick={(e) => onSubmit(e)}
                            >
                              Submit
                            </Button>
                          )}
                        </div>
                      )}
                    </form>
                  </div>
                  {result?.type == "auth/resetConfirm/fulfilled" && (
                    <div
                      className="text-center"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "center",
                        // gap: "10px",
                      }}
                    >
                      <h5>Your Password Changed,Please Login ... </h5>
                      <Lottie
                        animationData={UpdatePassword}
                        loop={false}
                        style={{ width: "150px", height: "120px" }}
                      />
                    </div>
                  )}
                  {result?.type == "auth/resetConfirm/rejected" && (
                    <div className="text-center">
                      <h3>Something Went Wrong Please Try Again! </h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
