import React, { useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import {
  useCurrentProvider,
  useProvider,
} from "../../../Common/Hooks/queryCalls";
import "../../../css/subscription.css";
import { formatCurrency, formatDate } from "../../../Services/functions";
import { Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton } from "@mui/material";
import { LottieLoading } from "../../../Common/LottieLoading";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import { cancelSubscription, app } from "../../../Services/dbService";
import { useHistory } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function convertTimeStamp(date) {
  const resultDate = new Date(date * 1000);
  return resultDate;
}

const Subscription = () => {
  const history = useHistory();
  const [cancelLoading, setCancelLoading] = React.useState(false);
  const [stripeOnBoardLoading, setStripeOnBoardLoading] = useState(false);
  const [stripeDashboardLoading, setStripeDashboardLoading] = useState(false);
  const [stripeRefreshLoading, setStripeRefreshLoading] = useState(false);
  const [stripeCreateLoading, setStripeCreateLoading] = useState(false);
  const {
    data: provider,
    isLoading: isProviderLoading,
    refetch,
  } = useCurrentProvider();
  // const { data, isLoading, refetch } = useProvider({
  //   providerId: currentProvider?.data?._id,
  // });

  // console.log("subscriptionDATA::", provider);
  if (isProviderLoading) {
    return (
      <h1>
        <LottieLoading />
      </h1>
    );
  }
  // if (!data) {
  //   return <h1>Loading</h1>;
  // }
  const handleClickCancelSubscription = async () => {
    setCancelLoading(true);
    const result = await cancelSubscription({
      subscriptionId: provider?.subscription?.id,
    });
    //console.log(result);

    if (result) {
      setCancelLoading(false);
      refetch();
      history.push("/page-subscription/payment/renew");
    }
  };

  const getStripeId = () => {
    if (provider && provider.stripeAccount) {
      if (provider.stripeAccount.id) {
        return provider.stripeAccount.id;
      }
      if (provider.stripeAccount.stripe_user_id) {
        return provider.stripeAccount.stripe_user_id;
      }
    }
  };

  const createStripeAccount = async () => {
    // setIsStripeAccountLoading(true);
    const result = await app.currentUser.callFunction("provider", {
      functionName: "createProviderStripeAccount",
      params: {
        providerId: `${provider._id}`,
      },
    });
    // console.log(result);
    refetch();
    // setProviderStripeAccount(result);
    // setIsStripeAccountLoading(false);
  };

  const goToStripeDashboard = async () => {
    // console.log(providerStripeAccount);
    // setIsStripeAccountLoading(true);
    setStripeDashboardLoading(true);
    const result = await app.currentUser.callFunction("stripeServices", {
      functionName: "createAccountLoginLink",
      params: {
        account: getStripeId(),
      },
    });
    // setIsStripeAccountLoading(false);
    setStripeDashboardLoading(false);
    if (result) {
      window.location.href = result.url;
    }
  };

  const goToStripeOnBoarding = async () => {
    // console.log(providerStripeAccount);
    // setIsStripeAccountLoading(true);
    setStripeOnBoardLoading(true);
    const result = await app.currentUser.callFunction("stripeServices", {
      functionName: "createAccountLink",
      params: {
        account: getStripeId(),
        refresh_url: `${window.location.origin}/page-subscription/account/refresh`,
        return_url: `${window.location.origin}/page-subscription/account/return`,
      },
    });
    setStripeOnBoardLoading(false);
    if (result) {
      window.location.href = result.url;
    }
  };

  const refreshStripeAccount = async () => {
    // setIsStripeAccountLoading(true);
    setStripeRefreshLoading(true);
    const result = await app.currentUser.callFunction("provider", {
      functionName: "refreshAccount",
      params: {
        providerId: `${provider._id}`,
        stripeId: getStripeId(),
      },
    });
    // console.log("result", result);
    if (result) {
      refetch();
      // setProviderStripeAccount(result);
    }
    setStripeRefreshLoading(false);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Stack spacing={2}>
          <Item>
            <div className="each-view-row-heading">
              <div className="each-element">
                <h4 style={{ color: "#2b87ae" }}>Subscription Details</h4>
                {/* <h4>{data?.subscription?.plan?.nickname}</h4> */}
              </div>
              <div className="each-element">
                {/* <h4>Amount:</h4>
                <h4>
                  {formatCurrency(data?.subscription?.plan?.amount / 100)}
                </h4> */}
                {cancelLoading ? (
                  <LoadingButton
                    size="small"
                    color="secondary"
                    // onClick={handleClick}
                    loading={cancelLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                  >
                    <span> Cancel Subscription</span>
                  </LoadingButton>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "0.675rem",
                        backgroundColor: "#fc0703",
                        width: 170,
                        height: "30px",
                      },
                      "@media (min-width: 601px) and (max-width: 960px)": {
                        fontSize: "0.775rem",
                        backgroundColor: "#fc0703",
                      },
                      "@media (min-width: 970px)": {
                        fontSize: "0.775rem",
                        backgroundColor: "#fc0703",
                      },
                    }}
                    onClick={handleClickCancelSubscription}
                  >
                    Cancel Subscription
                  </Button>
                )}
              </div>
            </div>
          </Item>
          <Item>
            <div className="each-view-row" style={{ fontFamily: "outfit" }}>
              <div className="each-element">
                <h4>Plan:</h4>
                <h4>{provider?.subscription?.plan?.nickname}</h4>
              </div>
              <div className="each-element">
                <h4>Amount:</h4>
                <h4>
                  {formatCurrency(provider?.subscription?.plan?.amount / 100)}
                </h4>
              </div>
            </div>{" "}
            <div className="each-view-row" style={{ fontFamily: "outfit" }}>
              <div className="each-element">
                <h4>Collection Method:</h4>
                <h4>{provider?.subscription?.collection_method}</h4>
              </div>
              <div className="each-element">
                <h4>Status:</h4>
                <h4>{provider?.subscription?.status}</h4>
              </div>
            </div>
            <div className="each-view-row" style={{ fontFamily: "outfit" }}>
              <div className="each-element">
                <h4>Date:</h4>
                <h4 className="suscription_date">{`${formatDate(
                  convertTimeStamp(provider?.subscription?.current_period_start)
                )} - ${formatDate(
                  convertTimeStamp(provider?.subscription?.current_period_end)
                )}`}</h4>
              </div>
              <div className="each-element">
                <h4>Invoice:</h4>
                <h4>
                  <a
                    target="_blank"
                    href={
                      provider?.subscription?.latest_invoice?.hosted_invoice_url
                    }
                  >
                    View Invoice
                  </a>
                </h4>
              </div>
            </div>
          </Item>

          <Item>
            <div className="each-view-row" style={{ fontFamily: "outfit" }}>
              <div className="each-element">
                <h4 style={{ color: "#2b87ae" }}>Stripe Details</h4>
                {/* <h4>{data?.subscription?.plan?.nickname}</h4> */}
              </div>
              <div className="each-element">
                {/* <h4>Amount:</h4>
                <h4>
                  {formatCurrency(data?.subscription?.plan?.amount / 100)}
                </h4> */}
                {/* <Button variant="contained">Cancel Subscription</Button> */}
              </div>
            </div>
          </Item>
          {provider?.stripeAccount ? (
            <Item>
              <div className="each-view-row" style={{ fontFamily: "outfit" }}>
                <div className="each-element">
                  <h4>Account Verification</h4>
                  {provider?.stripeAccount?.details_submitted &&
                  provider?.stripeAccount?.charges_enabled ? (
                    <h4>
                      <CheckCircleIcon />

                      {stripeRefreshLoading ? (
                        <CircularProgress fontSize={2} />
                      ) : (
                        <IconButton
                          onClick={() => {
                            refreshStripeAccount();
                          }}
                        >
                          <RefreshIcon />
                        </IconButton>
                      )}
                    </h4>
                  ) : (
                    <h4>
                      <CancelIcon />
                    </h4>
                  )}
                </div>
                <div className="each-element">
                  {/* <h4>Amount:</h4>
                <h4>
                  {formatCurrency(data?.subscription?.plan?.amount / 100)}
                </h4> */}
                  {provider?.stripeAccount?.details_submitted &&
                  provider?.stripeAccount?.charges_enabled ? (
                    stripeDashboardLoading ? (
                      <LoadingButton
                        size="small"
                        color="secondary"
                        // onClick={handleClick}
                        loading={stripeDashboardLoading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                      >
                        <span> Stripe Dashboard</span>
                      </LoadingButton>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          "@media (max-width: 600px)": {
                            fontSize: "0.675rem",
                            backgroundColor: "#2b87ae",
                          },
                          "@media (min-width: 601px) and (max-width: 960px)": {
                            fontSize: "0.775rem",
                            backgroundColor: "#2b87ae",
                          },
                          "@media (min-width: 970px)": {
                            fontSize: "0.775rem",
                            backgroundColor: "#2b87ae",
                          },
                        }}
                        style={{ fontFamily: "outfit" }}
                        onClick={() => {
                          goToStripeDashboard();
                        }}
                      >
                        Stripe Dashboard
                      </Button>
                    )
                  ) : stripeOnBoardLoading ? (
                    <LoadingButton
                      size="small"
                      color="secondary"
                      // onClick={handleClick}
                      loading={stripeOnBoardLoading}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                    >
                      <span> Stripe OnBoarding</span>
                    </LoadingButton>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{
                        "@media (max-width: 600px)": {
                          fontSize: "0.675rem",
                          backgroundColor: "#2b87ae",
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          fontSize: "0.775rem",
                          backgroundColor: "#2b87ae",
                        },
                        "@media (min-width: 970px)": {
                          fontSize: "0.775rem",
                          backgroundColor: "#2b87ae",
                        },
                      }}
                      style={{ fontFamily: "outfit" }}
                      onClick={() => {
                        goToStripeOnBoarding();
                      }}
                    >
                      Stripe OnBoarding
                    </Button>
                  )}
                </div>
              </div>
            </Item>
          ) : (
            <Item>
              <div className="each-view-row" style={{ fontFamily: "outfit" }}>
                <div className="each-element">
                  <h4>
                    Go to Stripe to complete your payment profile in order to
                    receive the payment to your account. Please your store will
                    not be visible to customers until your stripe account is
                    verified and Loko verifies your Account.
                  </h4>
                </div>
                <div className="each-element">
                  {/* <h4>Amount:</h4>
                <h4>
                  {formatCurrency(data?.subscription?.plan?.amount / 100)}
                </h4> */}
                  {stripeCreateLoading ? (
                    <LoadingButton
                      size="small"
                      color="secondary"
                      // onClick={handleClick}
                      loading={stripeCreateLoading}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                    >
                      <span> Create Stripe Account</span>
                    </LoadingButton>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{
                        "@media (max-width: 600px)": {
                          fontSize: "0.675rem",
                          backgroundColor: "#2b87ae",
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          fontSize: "0.775rem",
                          backgroundColor: "#2b87ae",
                        },
                        "@media (min-width: 970px)": {
                          fontSize: "0.775rem",
                          backgroundColor: "#2b87ae",
                        },
                      }}
                      style={{ fontFamily: "outfit" }}
                      onClick={() => {
                        createStripeAccount();
                      }}
                    >
                      Create Stripe Account
                    </Button>
                  )}
                </div>
              </div>
            </Item>
          )}
        </Stack>
      </Box>
    </div>
  );
};

export default Subscription;
