import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright 2023 All rights reserved Powered by Coderythm Technology
        </p>
      </div>
    </div>
  );
};

export default Footer;

// Copyright 2023 All rights reserved Powered by Coderythm Technology
