import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Typography, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "../../../css/subcategories.css";
import Autocomplete from "@mui/material/Autocomplete";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { updateSubCategory, addSubCategory } from "../../../Services/dbService";
import {
  useCategorySearch,
  useCategorySetting,
} from "../../../Common/Hooks/queryCalls";
const AddSubCategories = ({
  providerId,
  setOpen,
  edit,
  specificSubCategory = null,
  refetch,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [searchCategory, setSearchCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");

  const { data, isLoading } = useCategorySearch({
    search: searchCategory,
  });

  const categoriesSetting = useCategorySetting({
    categoryId,
  });
  // console.log("categoriesSetting:", categoriesSetting?.data);
  //console.log("search data::", data);
  // console.log("currentCategoryId:", categoryId);
  const validationSchema = Yup.object().shape({
    englishName: Yup.string().required(" Name is required"),
    arabicName: Yup.string().required("اسم  is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  async function addNewSubCategory(data) {
    if (edit) {
      // console.log("editSubCategory ", data);
      setLoading(true);
      const result = await updateSubCategory({
        item: specificSubCategory,
        data,
        categoryId,
      });
      // console.log("Editresult:", result);
      if (result) {
        setLoading(false);
        setOpen(false);
        refetch();
      }
    } else {
      // console.log("addSubCategory ", data);
      setLoading(true);
      const result = await addSubCategory({ providerId, data, categoryId });
      // console.log("Addresult:", result);
      if (result) {
        setLoading(false);
        setOpen(false);
        refetch();
      }
    }
  }

  useEffect(() => {
    setValue("englishName", specificSubCategory?.name?.en);
    setValue("arabicName", specificSubCategory?.name?.ar);
    setCategoryId(specificSubCategory?.categoryId);
  }, [specificSubCategory]);
  // console.log("specificSubCategory:", specificSubCategory);

  return (
    <div>
      <h4 >Add New SubCategory</h4>{" "}
      <form>
        <div className="subCategory-text-field">
          <div className="subCategory-text-field-row">
            <TextField
              sx={styleTextField}
              fullWidth
              label="Name*"
              id="fullWidth"
              size="small"
              {...register("englishName", { required: true })}
              error={errors.englishName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.englishName && errors.englishName?.message
                ? errors.englishName?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="subCategory-text-field-row">
            {" "}
            <TextField
              sx={styleTextField}
              fullWidth
              label="اسم*"
              id="fullWidth"
              size="small"
              {...register("arabicName", { required: true })}
              error={errors.arabicName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.arabicName && errors.arabicName?.message
                ? errors.arabicName?.message.toString()
                : null}
            </Typography>
          </div>
        </div>
        <div className="subCategory-text-field">
          <Autocomplete
            options={data || []}
            fullWidth
            size="small"
            value={categoriesSetting?.data?.label || ""}
            onChange={(event, newValue) => {
              setCategoryId(newValue.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Category"
                size="small"
                sx={styleTextField}
                onChange={(newValue) =>
                  setSearchCategory(newValue.target.value)
                }
              />
            )}
          />
        </div>

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          {!loading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => setOpen(false)}
              style={{ fontFamily:'outfit' }}
            >
              Close
            </Button>
          ) : (
            ""
          )}

          {loading ? (
            <LoadingButton
              size="small"
              color="secondary"
              // onClick={handleClick}
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              <span>Save</span>
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              onClick={handleSubmit(addNewSubCategory)}
              style={{ fontFamily:'outfit' }}
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </div>
  );
};

export default AddSubCategories;
const styleTextField = {
  "@media (max-width: 600px)": {
    "& .MuiInputBase-input": {
      height: "40px", // Adjust the height value as per your requirement
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    "& .MuiInputBase-input": {
      height: "50px", // Adjust the height value as per your requirement
    },
  },
  "@media (min-width: 970px)": {
    "& .MuiInputBase-input": {
      height: "50px", // Adjust the height value as per your requirement
    },
  },
};
