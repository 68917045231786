import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../Stores/auth.slice";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import { Typography, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "../../css/register.css";
import { registerDetails } from "../../Services/dbService";
import { useStripeProducts } from "../../Common/Hooks/queryCalls";
import { Redirect, useHistory } from "react-router-dom";
import logo from "../../images/logo.png";
import { formatCurrency } from "../../Services/functions";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

function Register(props) {
  const history = useHistory();
  const [selectedSubscriptionIndex, setSelectedSubscriptionIndex] =
    useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState({});
  const [registerLoading, setRegisterLoading] = useState(false);

  const handleButtonClick = (item, index) => {
    setSelectedSubscriptionIndex(index);
    setSelectedSubscription(item);
  };

  // console.log("Selected Div:", selectedSubscription);
  // console.log(selectedSubscriptionIndex);

  // const handleProceed = () => {
  //   if (selectedDiv) {
  //     // Proceed with the selected div
  //     console.log("Selected Div:", selectedDiv);
  //   } else {
  //     console.log("Please select a div");
  //   }
  // };
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    englishName: Yup.string().required(" Business Name(English) is required"),
    arabicName: Yup.string().required("Business Name(Arabic)  is required"),
    englishBio: Yup.string().required(" Business Bio(English)  is required"),
    arabicBio: Yup.string().required("Business Bio(Arabic)  is required"),
    number: Yup.string().required("Mobile Number  is required"),
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .required("Password  is required")
      .min(6, "Password is too short - should be 6 chars minimum."),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { data, isLoading } = useStripeProducts();
  // console.log("stripeData:", data);
  function redirect() {
    setRegisterLoading(false);
    // history.push(`${window.location.origin}/page-subscription/payment`);
    window.location.assign(
      `${window.location.origin}/page-subscription/payment`
    );
  }

  async function onSignUp(data) {
    // console.log("register:", data);
    setRegisterLoading(true);
    const result = await registerDetails({ data, selectedSubscription });
    if (result) {
      const loggedUser = dispatch(
        authActions.register({ email: data.email, password: data.password })
      );
      // await app.currentUser.refreshCustomData();
      if (loggedUser) {
        setTimeout(redirect, 2000);
      }
      // <Redirect to="/login" state={{ from: location }} />;
    }
    // console.log("userRegistered:", result);
    return result;
  }
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="logo-div">
                      <img style={{ width: "100px" }} src={logo} alt="" />
                      <h5>For Business</h5>
                    </div>

                    <h4 className="text-center mb-4 ">Sign up your account</h4>
                    {props.errorMessage && (
                      <div className="bg-red-300 text-danger border border-red-900 p-1 my-2">
                        {props.errorMessage}
                      </div>
                    )}
                    {props.successMessage && (
                      <div className="bg-green-300 text-danger text-green-900  p-1 my-2">
                        {props.successMessage}
                      </div>
                    )}
                    <form onSubmit={onSignUp}>
                      <div className="register-text-field">
                        <TextField
                          fullWidth
                          label="Name of Your Business(English)*"
                          id="fullWidth"
                          {...register("englishName", { required: true })}
                          error={errors.englishName ? true : false}
                        />
                        <Typography
                          variant="inherit"
                          color="textSecondary"
                          sx={{ textAlign: "center" }}
                        >
                          {errors &&
                          errors.englishName &&
                          errors.englishName?.message
                            ? errors.englishName?.message.toString()
                            : null}
                        </Typography>
                        <TextField
                          fullWidth
                          label="Name of Your Business(Arabic)"
                          id="fullWidth"
                          {...register("arabicName", { required: true })}
                          error={errors.arabicName ? true : false}
                        />
                        <Typography
                          variant="inherit"
                          color="textSecondary"
                          sx={{ textAlign: "center" }}
                        >
                          {errors &&
                          errors.arabicName &&
                          errors.arabicName?.message
                            ? errors.arabicName?.message.toString()
                            : null}
                        </Typography>

                        <TextField
                          fullWidth
                          label="Email ID*"
                          id="fullWidth"
                          {...register("email", { required: true })}
                          error={errors.email ? true : false}
                        />
                        <Typography
                          variant="inherit"
                          color="textSecondary"
                          sx={{ textAlign: "center" }}
                        >
                          {errors && errors.email && errors.email?.message
                            ? errors.email?.message.toString()
                            : null}
                        </Typography>
                        <TextField
                          fullWidth
                          label="Password*"
                          id="fullWidth"
                          type="password"
                          {...register("password", { required: true })}
                          error={errors.password ? true : false}
                        />
                        <Typography
                          variant="inherit"
                          color="textSecondary"
                          sx={{ textAlign: "center" }}
                        >
                          {errors && errors.password && errors.password?.message
                            ? errors.password?.message.toString()
                            : null}
                        </Typography>
                        <TextField
                          fullWidth
                          label="Mobile Number*"
                          id="fullWidth"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +971
                              </InputAdornment>
                            ),
                          }}
                          {...register("number", { required: true })}
                          error={errors.number ? true : false}
                        />
                        <Typography
                          variant="inherit"
                          color="textSecondary"
                          sx={{ textAlign: "center" }}
                        >
                          {errors && errors.number && errors.number?.message
                            ? errors.number?.message.toString()
                            : null}
                        </Typography>

                        <TextField
                          fullWidth
                          label="Short Bio About the Business(English)*"
                          id="fullWidth"
                          {...register("englishBio", { required: true })}
                          error={errors.englishBio ? true : false}
                        />
                        <Typography
                          variant="inherit"
                          color="textSecondary"
                          sx={{ textAlign: "center" }}
                        >
                          {errors &&
                          errors.englishBio &&
                          errors.englishBio?.message
                            ? errors.englishBio?.message.toString()
                            : null}
                        </Typography>
                        <TextField
                          fullWidth
                          label="Short Bio About the Business(Arabic)*"
                          id="fullWidth"
                          {...register("arabicBio", { required: true })}
                          error={errors.arabicBio ? true : false}
                        />
                        <Typography
                          variant="inherit"
                          color="textSecondary"
                          sx={{ textAlign: "center" }}
                        >
                          {errors &&
                          errors.arabicBio &&
                          errors.arabicBio?.message
                            ? errors.arabicBio?.message.toString()
                            : null}
                        </Typography>

                        <div className="subscription-plan-div">
                          <h5>Select one of our plans to get started</h5>
                          <div className="plan-div">
                            {data?.map((item, index) => {
                              return (
                                <div className="sub-plan" key={index}>
                                  <h4 className="plan-name">
                                    {item?.nickname}
                                  </h4>
                                  <h3 className="plan-amt">{`${formatCurrency(
                                    item?.unit_amount / 100
                                  )}/${item?.recurring?.interval}`}</h3>
                                  <h6 className="plan-des">
                                    {` you will be billed ${formatCurrency(
                                      item?.unit_amount / 100
                                    )}  per ${item?.recurring?.interval}`}
                                  </h6>{" "}
                                  <Button
                                    variant="contained"
                                    sx={{
                                      backgroundColor:
                                        selectedSubscriptionIndex === index
                                          ? "#037ffc"
                                          : "#e3e1ed",
                                      color:
                                        selectedSubscriptionIndex === index
                                          ? "white"
                                          : "black",
                                    }}
                                    onClick={() =>
                                      handleButtonClick(item, index)
                                    }
                                  >
                                    {selectedSubscriptionIndex === index
                                      ? "Selected"
                                      : "Select"}
                                  </Button>
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        <div className="register-button">
                          {registerLoading ? (
                            <LoadingButton
                              loading
                              loadingPosition="start"
                              startIcon={<SaveIcon />}
                              variant="outlined"
                            >
                              Register
                            </LoadingButton>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={handleSubmit(onSignUp)}
                              sx={{
                                "@media (max-width: 600px)": {
                                  fontSize: "0.6rem",
                                  backgroundColor: "#2b87ae",
                                },
                                "@media (min-width: 601px) and (max-width: 960px)":
                                  {
                                    fontSize: "0.8rem",
                                    backgroundColor: "#2b87ae",
                                  },
                                "@media (min-width: 970px)": {
                                  fontSize: "0.8rem",
                                  backgroundColor: "#2b87ae",
                                },
                              }}
                            >
                              Register
                            </Button>
                          )}
                        </div>
                      </div>
                    </form>
                    <div className="new-account mt-3 ">
                      <p>
                        Already have an account?{" "}
                        <Link className="text-primary" to="/login">
                          Sign in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// const mapStateToProps = (state) => {
//     return {
//         errorMessage: state.auth.errorMessage,
//         successMessage: state.auth.successMessage,
//         showLoading: state.auth.showLoading,
//     };
// };

export default Register;
