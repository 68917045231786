import React from "react";
import {
  CardElement,
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "@mui/material";
import { useCurrentProvider } from "../../Common/Hooks/queryCalls";

const CheckoutForm = ({ setIsPaymentLoading }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isReady, setIsReady] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const { refetch } = useCurrentProvider();

  const paymentElementOptions = {
    layout: "accordion", //tabs
  };

  React.useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    setIsPaymentLoading(true);

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // switch (paymentIntent.status) {
      //   case 'succeeded':
      //     this.stripeMessage = 'Payment Successfully Completed';
      //     window.location.href = `${environment.provider_url}/subscription/account`;
      //     break;
      //   case 'processing':
      //     this.stripeMessage = 'Your payment is processing.';
      //     break;
      //   case 'requires_payment_method':
      //     this.stripeMessage =
      //       'Your payment was not successful, please try again.';
      //     this.validateIntentAndInitializeStripePayment();
      //     break;
      //   default:
      //     this.stripeMessage = 'Something went wrong.';
      //     this.validateIntentAndInitializeStripePayment();
      //     break;
      // }
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          window.location.href = `${window.location.origin}/page-subscription/account`;
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
      setIsPaymentLoading(false);
    });
  }, [stripe]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || !isReady) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
       
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/page-subscription/payment/confirm`,
      },
    });
    refetch();

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);

    // if (elements == null) {
    //   return;
    // }

    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: elements.getElement(CardElement),
    // });
  };
  return (
    <form onSubmit={handleSubmit}>
      {/* <CardElement /> */}
      <PaymentElement
        id="payment-element"
        options={paymentElementOptions}
        onReady={() => setIsReady(true)}
      />
      {/* <button type="submit" disabled={!stripe || !elements}>
        Pay
      </button> */}
      {stripe && elements && isReady ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <Button
            variant="contained"
            disabled={!stripe || !elements}
            type="submit"
            sx={{
              backgroundColor: "#037ffc",
              color: "white",
            }}
          >
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay Now"
              )}
            </span>
          </Button>
          {message && <div id="payment-message">{message}</div>}
        </div>
      ) : null}
    </form>
  );
};

export default CheckoutForm;
