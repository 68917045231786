import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Rating from "@mui/material/Rating";
import {
  useCurrentProvider,
  useReviews,
} from "../../../Common/Hooks/queryCalls";
import { formatDate } from "../../../Services/functions";
import { LottieLoading } from "../../../Common/LottieLoading";

const Reviews = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  // const providerId = "5eda1de77e3e21512c21940f";
  const currentProvider = useCurrentProvider();
  const { data, isLoading, refetch } = useReviews({
    providerId: currentProvider?.data?._id,
  });
  //console.log("reviews::", data);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    );
  }
  // if (!data) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       Loading
  //     </h1>
  //   );
  // }

  // if (data?.length == 0) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       No Items Available
  //     </h1>
  //   );
  // }
  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            {data?.length == 0 && (
              <caption style={{ fontFamily: "outfit" }}>
                No Items Available
              </caption>
            )}
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={tableHeadStyle}
                  style={{ fontFamily: "outfit" }}
                >
                  Date
                </TableCell>
                <TableCell
                  align="center"
                  sx={tableHeadStyle}
                  style={{ fontFamily: "outfit" }}
                >
                  Rating
                </TableCell>
                <TableCell
                  align="center"
                  sx={tableHeadStyle}
                  style={{ fontFamily: "outfit" }}
                >
                  Review
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((item) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={item._id}
                    >
                      <TableCell
                        align="center"
                        sx={tableContentStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {formatDate(item?.metadata?.createdAt)}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableContentStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        <Rating
                          name="read-only"
                          value={item?.rating || 0}
                          readOnly
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableContentStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {item?.review}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{ fontFamily: "outfit" }}
          sx={tableContentStyle}
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={data?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default Reviews;
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.775rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.875rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.875rem",
  },
};

const tableContentStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.65rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.75rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.75rem",
  },
};
