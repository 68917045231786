import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  getCurrentProvider,
  getProvider,
  getBranches,
  getBranchModifiers,
  getBranchProducts,
  getModifiers,
  getSubCategories,
  getProducts,
  getCategorySearch,
  getCategorySetting,
  getProductCategorySearch,
  getCompletedOrders,
  getOngoingOrders,
  getSpecificSubcatogories,
  getReviews,
  getAddons,
  getOneOrderDetail,
  getProductsModifiers,
  getPrefixCheck,
  getBranchesSearch,
  getOrdersBtwDates,
  getStripeProducts,
  getOrdersThisyear,
  getOrdersThisMonth,
  getOrdersThisWeek,
  getOrdersThisDay,
  getSpecificSubcatogoriesSetting,
  getBranchCity,
  getBranchCitySetting,
} from "../../Services/dbService";

export const useCurrentProvider = () => {
  const authUser = useSelector((x) => x.auth.user);
  const customDataUser = useSelector((x) => x.auth.customData);
  // console.log("authUser:", authUser);
  // console.log("customDataUser:", customDataUser);
  // console.log("customDataUser entityId:", customDataUser?.entityId);
  return useQuery(
    [
      "currentProvider",
      authUser,
      customDataUser,
      { customerId: customDataUser?.entityId || null },
    ],
    () => getCurrentProvider({ providerId: customDataUser?.entityId || null })
  );
};

export const useProvider = ({ providerId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["providerDetails", authUser, { providerId }], () =>
    getProvider({ providerId })
  );
};

export const useBranches = ({ providerId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["branches", authUser, { providerId }], () =>
    getBranches({ providerId })
  );
};

export const useBranchModifiers = ({ branchId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["branchModifiers", authUser, { branchId }], () =>
    getBranchModifiers({ branchId })
  );
};

export const useBranchProducts = ({ branchId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["branchProducts", authUser, { branchId }], () =>
    getBranchProducts({ branchId })
  );
};

export const useModifiers = ({ providerId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["modifiers", authUser, { providerId }], () =>
    getModifiers({ providerId })
  );
};

export const useSubCategories = ({ providerId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["subCategories", authUser, { providerId }], () =>
    getSubCategories({ providerId })
  );
};

export const useProducts = ({ providerId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["products", authUser, { providerId }], () =>
    getProducts({ providerId })
  );
};

export const useProductsModifiers = ({ menuId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["productsModifier", authUser, { menuId }], () =>
    getProductsModifiers({ menuId })
  );
};

export const useCategorySearch = ({ search }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["categoriesDetails", authUser, { search }], () =>
    getCategorySearch({ search })
  );
};

export const useCategorySetting = ({ categoryId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["categoriesSetting", authUser, { categoryId }], () =>
    getCategorySetting({ categoryId })
  );
};

export const useProductCategorySearch = ({ search }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["productcategoriesDetails", authUser, { search }], () =>
    getProductCategorySearch({ search })
  );
};

export const useCompletedOrders = ({ providerId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["completedorders", authUser, { providerId }], () =>
    getCompletedOrders({ providerId })
  );
};

export const useOngoingOrders = ({ providerId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["ongoingorders", authUser, { providerId }], () =>
    getOngoingOrders({ providerId })
  );
};

export const useOneOrderDetails = ({ orderId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["orderDetails", authUser, { orderId }], () =>
    getOneOrderDetail({ orderId })
  );
};

export const useSpecificSubcatogories = ({ subId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["specificSubcatogries", authUser, { subId }], () =>
    getSpecificSubcatogories({ subId })
  );
};

export const useSpecificSubcatogoriesSetting = ({ subCategoryId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(
    ["specificSubcatogriesSetting", authUser, { subCategoryId }],
    () => getSpecificSubcatogoriesSetting({ subCategoryId })
  );
};

export const useReviews = ({ providerId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["reviews", authUser, { providerId }], () =>
    getReviews({ providerId })
  );
};

export const useAddons = ({ providerId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["addons", authUser, { providerId }], () =>
    getAddons({ providerId })
  );
};

export const usePrefixCheck = ({ fieldValue }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["field", authUser, { fieldValue }], () =>
    getPrefixCheck({ fieldValue })
  );
};

export const useBranchesSearch = ({ providerId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["branches", authUser, { providerId }], () =>
    getBranchesSearch({ providerId })
  );
};

// export const useOrdersBtwDates = ({
//   startDate,
//   endDate,
//   providerId,
//   branchId,
// }) => {
//   const authUser = useSelector((x) => x.auth.user);
//   return useQuery(
//     ["orderbtwdates", authUser, { startDate, endDate, providerId, branchId }],
//     () =>
//       getOrdersBtwDates(
//         { startDate, endDate, providerId, branchId },
//         {
//           enabled: false, // Disable automatic query execution
//         }
//       )
//   );
// };

export const useStripeProducts = () => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["stripeProducts", authUser], () => getStripeProducts());
};

export const useOrdersBtwDates = ({
  startDate,
  endDate,
  providerId,
  branchId,
}) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(
    ["btwdates", authUser, { startDate, endDate, providerId, branchId }],
    () => getOrdersBtwDates({ startDate, endDate, providerId, branchId })
  );
};

export const useOrdersThisYear = ({
  startOfYear,
  endOfYear,
  providerId,
  branchId,
}) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(
    ["thisYear", authUser, { startOfYear, endOfYear, providerId, branchId }],
    () => getOrdersThisyear({ startOfYear, endOfYear, providerId, branchId })
  );
};

export const useOrdersThisMonth = ({
  startOfMonth,
  endOfMonth,
  providerId,
  branchId,
}) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(
    ["btwdates", authUser, { startOfMonth, endOfMonth, providerId, branchId }],
    () => getOrdersThisMonth({ startOfMonth, endOfMonth, providerId, branchId })
  );
};

export const useOrdersThisWeek = ({
  startOfWeek,
  endOfWeek,
  providerId,
  branchId,
}) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(
    ["btwdates", authUser, { startOfWeek, endOfWeek, providerId, branchId }],
    () => getOrdersThisWeek({ startOfWeek, endOfWeek, providerId, branchId })
  );
};

export const useOrdersTodays = ({
  startTodays,
  endTodays,
  providerId,
  branchId,
}) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(
    ["btwdates", authUser, { startTodays, endTodays, providerId, branchId }],
    () => getOrdersThisDay({ startTodays, endTodays, providerId, branchId })
  );
};

export const useBranchCity = () => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["branchCity", authUser], () => getBranchCity());
};

export const useBranchCitySetting = ({ cityId }) => {
  const authUser = useSelector((x) => x.auth.user);
  return useQuery(["branchCitySetting", authUser, { cityId }], () =>
    getBranchCitySetting({ cityId })
  );
};
