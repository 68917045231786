import React, { useState } from "react";
import "../../../css/revenue.css";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { formatDate, formatCurrency } from "../../../Services/functions";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const RevenueOrderDetails = ({ setOpen, specificOrder }) => {
  //console.log(specificOrder);
  return (
    <div className="revenue-order-main-div">
      <Stack spacing={2}>
        <Item>
          {" "}
          <div className="revenue-order-details-row" style={{ fontFamily: "outfit" }}>
            <div className="revenue-order-details-element">
              <h4>Order # :</h4>
              <h4>{specificOrder?.refNumber}</h4>
            </div>{" "}
            <div className="revenue-order-details-element">
              <h4>Order Status :</h4>
              <h4>{specificOrder?.status}</h4>
            </div>
          </div>
        </Item>
        <Item>
          <div className="revenue-order-details-row" style={{ fontFamily: "outfit" }}>
            <div className="revenue-order-details-element">
              <h4>Date:</h4>
              <h4>{formatDate(specificOrder?.date)}</h4>
            </div>
            <div className="revenue-order-details-element">
              <h4>Customer:</h4>
              <h4>{`${specificOrder?.customer?.firstName}  ${specificOrder?.customer?.lastName} (+971 ${specificOrder?.customer?.number})`}</h4>
            </div>
          </div>
        </Item>

        <Item>
          {" "}
          <div className="revenue-order-details-row" style={{ fontFamily: "outfit" }}>
            <div className="revenue-order-details-element">
              <h4>Total Amount :</h4>
              <h4>{formatCurrency(specificOrder?.amount)}</h4>
            </div>
            <div className="revenue-order-details-element">
              <h4>Address:</h4>
              <h4>{`${specificOrder?.address?.firstName}  ${specificOrder?.address?.lastName}, ${specificOrder?.address?.address}, ${specificOrder?.address?.email}, +971 ${specificOrder?.address?.number}`}</h4>
            </div>
          </div>
        </Item>
        <Item>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={tableHeadStyle} style={{ fontFamily: "outfit" }}>Item</TableCell>
                  <TableCell align="right" sx={tableHeadStyle} style={{ fontFamily: "outfit" }}>Oty</TableCell>
                  <TableCell align="right" sx={tableHeadStyle} style={{ fontFamily: "outfit" }}>Addons</TableCell>
                  <TableCell align="right" sx={tableHeadStyle} style={{ fontFamily: "outfit" }}>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {specificOrder?.items?.map((row) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={tableContentStyle} style={{ fontFamily: "outfit" }}>
                      {row?.name?.en || row?.item?.name?.en}
                    </TableCell>
                    <TableCell align="right" sx={tableContentStyle} style={{ fontFamily: "outfit" }}>{row?.quantity}</TableCell>
                    <TableCell align="right" sx={tableContentStyle} style={{ fontFamily: "outfit" }}>
                      {row?.modifiers?.map((value, index) => (
                        // console.log(value?.modifierName?.en)
                        <h6
                          className="revenue-order-addon-h6"
                          key={index}
                        >{`${value?.modifierName?.en},`}</h6>
                      ))}
                    </TableCell>
                    <TableCell align="right" sx={tableContentStyle} style={{ fontFamily: "outfit" }}>
                      {formatCurrency(row?.price || row?.item?.price)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Item>
      </Stack>
    </div>
  );
};

export default RevenueOrderDetails;
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.775rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.875rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.875rem",
    
  },
};

const tableContentStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.65rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.75rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.75rem",
  },
};
