import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  useCurrentProvider,
  useSubCategories,
} from "../../../Common/Hooks/queryCalls";
import AddSubCategories from "./AddSubCategories";
import { deleteSubCategory } from "../../../Services/dbService";
import { LottieLoading } from "../../../Common/LottieLoading";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

const SubCategories = () => {
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [specificSubCategory, setSpecificSubCategory] = useState({});
  const [edit, setEdit] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});

  // const providerId = "5eda1de77e3e21512c21940f";
  const currentProvider = useCurrentProvider();
  const { data, isLoading, refetch } = useSubCategories({
    providerId: currentProvider?.data?._id,
  });
  // console.log("subcategories::", data);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setSpecificSubCategory({});
  };

  const handleEdit = (item) => {
    setSpecificSubCategory(item);
    setOpen(true);
    setEdit(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickDelete = async () => {
    setDeleteLoading(true);
    // console.log("item going to be deleted::", deleteItem);
    const result = await deleteSubCategory({ subCategoryId: deleteItem?._id });

    // console.log("branch deleted successfully:-", result);
    if (result) {
      refetch();
      setOpenDialog(false);
      setDeleteLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteItem({});
  };

  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    );
  }
  if (!data) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    );
  }

  // if (data?.length == 0) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       No Items Available
  //     </h1>
  //   );
  // }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"  style={{ fontFamily:'outfit' }}>
          {"Are you sure you want to delete this Subcategory?"}
        </DialogTitle>

        <DialogActions>
          {!deleteLoading ? (
            <Button onClick={handleCloseDialog}  style={{ fontFamily:'outfit' }}>No</Button>
          ) : (
            ""
          )}

          {deleteLoading ? (
            <LoadingButton
              size="small"
              color="secondary"
              // onClick={handleClick}
              loading={deleteLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              <span>Yes</span>
            </LoadingButton>
          ) : (
            <Button onClick={handleClickDelete} autoFocus  style={{ fontFamily:'outfit' }}>
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
            },
          }}
          style={{ fontFamily:'outfit' }}
        >
          <AddSubCategories
            providerId={currentProvider?.data?._id}
            setOpen={setOpen}
            edit={edit}
            specificSubCategory={specificSubCategory}
            refetch={refetch}
          />
        </Box>
      </Modal>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "5px",
        }}
      >
        <Button
          sx={{
            "@media (max-width: 600px)": {
              fontSize: "0.6rem",
              backgroundColor: "#2b87ae",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              fontSize: "0.8rem",
              backgroundColor: "#2b87ae",
            },
            "@media (min-width: 970px)": {
              fontSize: "0.8rem",
              backgroundColor: "#2b87ae",
            },
          }}
          variant="contained"
          onClick={() => setOpen(true)}
          style={{ fontFamily:'outfit' }}
        >
          Add New Subcategory
        </Button>
      </div>
      <div>
        {" "}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              {data?.length == 0 && <caption  style={{ fontFamily:'outfit' }}>No Items Available</caption>}
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily:'outfit' }}
                  >
                    Item Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily:'outfit' }}
                  >
                    Category Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily:'outfit' }}
                  >
                    Is Active
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily:'outfit' }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item._id}
                      >
                        <TableCell align="center" sx={tableContentStyle}  style={{ fontFamily:'outfit' }}>
                          {item?.name?.en}
                          {""}
                          {` (${item?.name?.ar})`}
                        </TableCell>
                        <TableCell align="center" sx={tableContentStyle}  style={{ fontFamily:'outfit' }}>
                          {item?.getCategories?.name?.en}
                          {""}
                          {` (${item?.getCategories?.name?.ar})`}
                        </TableCell>
                        <TableCell align="center" sx={tableContentStyle} >
                          {item?.metadata?.isActive ? (
                            <CheckCircleIcon fontSize="small" />
                          ) : (
                            <CancelIcon fontSize="small" />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {/* <IconButton onClick={() => {}}>
                                <VisibilityIcon fontSize="small" />
                              </IconButton> */}
                              <IconButton
                                onClick={() => {
                                  handleEdit(item);
                                }}
                              >
                                <EditNoteIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setOpenDialog(true);
                                  setDeleteItem(item);
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </div>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
           style={{ fontFamily:'outfit' }}
            sx={tableContentStyle}
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={data.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
};

export default SubCategories;
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.775rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.875rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.875rem",
    
  },
};

const tableContentStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.65rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.75rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.75rem",
  },
};
