import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Realm from "realm-web";
import { app } from "../Services/dbService";
import { Redirect } from "react-router-dom";
// import { history } from "../Services/history";

// create slice
const name = "auth";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  reducers,
  extraReducers,
});

// exports
export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

function createInitialState() {
  if (!app.currentUser)
    return {
      user: null,
      customData: null,
      userType: null,
      error: null,
    };

  app.currentUser.refreshCustomData();

  return {
    user: app.currentUser.profile,
    customData: app.currentUser.customData,
    type: app.currentUser.providerType,
    error: null,
  };
}

function createReducers() {
  return {
    logout,
  };

  function logout(redirectEnable = true, state) {
    state.user = null;
    state.type = null;
    state.error = null;

    if (app.currentUser) {
      app.currentUser.logOut();
    }
    // if (redirectEnable) {
    //   history.navigate('/');
    // }
  }
}
function createExtraActions() {
  return {
    login: login(),
    register: register(),
    resetPasswordSendEmail: resetPasswordSendEmail(),
    resetPasswordConfirmEmail: resetPasswordConfirmEmail(),
    // loginGuest: loginGuest(),
    // loginGoogle: loginGoogle(),
    // loginFacebook: loginFacebook(),
    // loginApple: loginApple(),
    // loginViaOTP: loginViaOTP(),
  };

  function login() {
    return createAsyncThunk(`${name}/login`, async ({ email, password }) => {
      await app.logIn(Realm.Credentials.emailPassword(email, password));
      if (app && app.currentUser && app.currentUser.profile) {
        return {
          user: app.currentUser.profile,
          customData: app.currentUser.customData,
          type: app.currentUser.providerType,
        };
      }
      return app.currentUser;
    });
  }

  function register() {
    return createAsyncThunk(`${name}/register`, async ({ email, password }) => {
      const result = await app.emailPasswordAuth.registerUser({
        email,
        password,
      });
      // console.log(result);
      // return result;
      await app.logIn(Realm.Credentials.emailPassword(email, password));
      if(app && app.currentUser){
        await app.currentUser.refreshCustomData();
      }
      if (app && app.currentUser && app.currentUser.profile) {
        return {
          user: app.currentUser.profile,
          customData: app.currentUser.customData,
          type: app.currentUser.providerType,
        };
      }
      return app.currentUser;
    });
  }

  function resetPasswordSendEmail() {
    return createAsyncThunk(`${name}/resetSend`, async ({ email }) => {
      return await app.emailPasswordAuth.sendResetPasswordEmail({ email });
    });
  }

  function resetPasswordConfirmEmail() {
    return createAsyncThunk(
      `${name}/resetConfirm`,
      async ({ password, token, tokenId }) => {
        return await app.emailPasswordAuth.resetPassword({
          password,
          token,
          tokenId,
        });
      }
    );
  }

  //   function loginGuest() {
  //     return createAsyncThunk(`${name}/loginGuest`, async () => {
  //       // app.currentUser
  //       const credentials = Realm.Credentials.anonymous();
  //       await app.logIn(credentials);
  //       if (app && app.currentUser && app.currentUser.profile) {
  //         return {
  //           user: app.currentUser.profile,
  //           customData: app.currentUser.customData,
  //           type: app.currentUser.providerType,
  //         };
  //       }
  //       return app.currentUser;
  //     });
  //   }

  //   function loginGoogle() {
  //     return createAsyncThunk(`${name}/loginGoogle`, async () => {
  //       // const currentUrl = window.location.pathname;
  //       localStorage.setItem("authRedirectUrl", window.location.pathname);
  //       // console.log(currentUrl);
  //       const credentials = Realm.Credentials.google({ redirectUrl });

  //       // let previousUser: any;
  //       // if (app.currentUser) {
  //       //   previousUser = app.currentUser;
  //       //   // const linked = await previousUser.linkCredentials(
  //       //   //   credentials
  //       //   // );
  //       //   // console.log('linked', linked);
  //       // }
  //       await app.logIn(credentials);
  //       if (app && app.currentUser && app.currentUser.profile) {
  //         // if (previousUser) {
  //         //   await previousUser.linkCredentials(credentials);
  //         // }
  //         return {
  //           user: app.currentUser.profile,
  //           customData: app.currentUser.customData,
  //           type: app.currentUser.providerType,
  //         };
  //       }
  //       return app.currentUser;
  //     });
  //   }

  //   function loginFacebook(accessToken = null) {
  //     return createAsyncThunk(
  //       `${name}/loginFacebook`,
  //       async (accessToken = null) => {
  //         // const currentUrl = window.location.pathname;
  //         localStorage.setItem("authRedirectUrl", window.location.pathname);
  //         let credentials = Realm.Credentials.facebook(redirectUrl);

  //         if (accessToken) {
  //           credentials = Realm.Credentials.facebook(accessToken);
  //         }
  //         await app.logIn(credentials);
  //         if (app && app.currentUser && app.currentUser.profile) {
  //           return {
  //             user: app.currentUser.profile,
  //             customData: app.currentUser.customData,
  //             type: app.currentUser.providerType,
  //           };
  //         }
  //         return app.currentUser;
  //       }
  //     );
  //   }

  //   function loginApple(id_token = null) {
  //     return createAsyncThunk(`${name}/loginApple`, async (id_token = null) => {
  //       // localStorage.setItem('authRedirectUrl', window.location.pathname);
  //       let credentials = Realm.Credentials.apple(redirectUrl);
  //       if (id_token) {
  //         credentials = Realm.Credentials.apple(id_token);
  //       }
  //       // const currentUrl = window.location.href;
  //       // console.log('credentials', credentials);
  //       await app.logIn(credentials);
  //       if (app && app.currentUser && app.currentUser.profile) {
  //         console.log("app.currentUser", app.currentUser);
  //         return {
  //           user: app.currentUser.profile,
  //           customData: app.currentUser.customData,
  //           type: app.currentUser.providerType,
  //         };
  //       }
  //       return app.currentUser;
  //     });
  //   }

  //   function loginViaOTP() {
  //     return createAsyncThunk(
  //       `${name}/loginViaOTP`,
  //       async ({ number }) => {
  //         const credentials = Realm.Credentials.function({
  //           loginFunction: "otpLogin",
  //           number,
  //         });
  //         await app.logIn(credentials);
  //         if (app && app.currentUser && app.currentUser.profile) {
  //           return {
  //             user: app.currentUser.profile,
  //             customData: app.currentUser.customData,
  //             type: app.currentUser.providerType,
  //           };
  //         }
  //         return app.currentUser;
  //       }
  //     );
  //   }
}

function createExtraReducers() {
  return (builder) => {
    login();
    register();
    resetPasswordSendEmail();
    resetPasswordConfirmEmail();
    // loginGuest();
    // loginGoogle();
    // loginFacebook();
    // loginApple();
    // loginViaOTP();
    function login() {
      const { pending, fulfilled, rejected } = extraActions.login;
      builder
        .addCase(pending, (state) => {
          state.error = null;
        })
        .addCase(fulfilled, (state, action) => {
          if (app && app.currentUser) {
            app.currentUser.refreshCustomData();
            state.user = app.currentUser.profile;
            state.customData = app.currentUser.customData;
            state.type = app.currentUser.providerType;
            state.error = null;
          }
          <Redirect to="/basic-details" state={{ from: location }} />;
          // history.push("/basic-details");
          // get return url from location state or default to home page
          // <Redirect to="/basic-details" state={{ from: location }} />;
          // const { from } = history.location.state || {
          //   // from: { pathname: '/' },
          //   from: "/",
          // };
          // if (from) {
          //   history.navigate(from);
          // }
        })
        .addCase(rejected, (state, action) => {
          state.error = action.error;
        });
    }

    function register() {
      const { pending, fulfilled, rejected } = extraActions.register;
      builder
        .addCase(pending, (state) => {
          state.error = null;
        })
        .addCase(fulfilled, (state, action) => {
          <Redirect to="/login" state={{ from: location }} />;
        })
        .addCase(rejected, (state, action) => {
          state.error = action.error;
        });
    }

    function resetPasswordSendEmail() {
      const { pending, fulfilled, rejected } =
        extraActions.resetPasswordSendEmail;
      builder
        .addCase(pending, (state) => {
          state.error = null;
        })
        .addCase(fulfilled, (state, action) => {})
        .addCase(rejected, (state, action) => {
          state.error = action.error;
        });
    }

    function resetPasswordConfirmEmail() {
      const { pending, fulfilled, rejected } =
        extraActions.resetPasswordConfirmEmail;
      builder
        .addCase(pending, (state) => {
          state.error = null;
        })
        .addCase(fulfilled, (state, action) => {})
        .addCase(rejected, (state, action) => {
          state.error = action.error;
        });
    }

    //   function loginGuest() {
    //     const { pending, fulfilled, rejected } = extraActions.loginGuest;
    //     builder
    //       .addCase(pending, (state: IUserStore) => {
    //         state.error = null;
    //       })
    //       .addCase(fulfilled, (state: IUserStore, action: { payload: any }) => {
    //         if (app && app.currentUser) {
    //           app.currentUser.refreshCustomData();
    //           state.user = app.currentUser.profile;
    //           state.customData = app.currentUser.customData;
    //           state.type = app.currentUser.providerType;
    //           state.error = null;
    //         }
    //         if (history && history.location && history.location.state) {
    //           // get return url from location state or default to home page
    //           const { from } = history.location.state || {
    //             from: { pathname: "/" },
    //           };
    //           if (from) {
    //             history.navigate(from);
    //           }
    //         }
    //       })
    //       .addCase(rejected, (state: IUserStore, action: { error: any }) => {
    //         state.error = action.error;
    //       });
    //   }
    //   function loginGoogle() {
    //     const { pending, fulfilled, rejected } = extraActions.loginGoogle;
    //     builder
    //       .addCase(pending, (state: IUserStore) => {
    //         state.error = null;
    //       })
    //       .addCase(fulfilled, (state: IUserStore, action: { payload: any }) => {
    //         if (app && app.currentUser) {
    //           app.currentUser.refreshCustomData();
    //           state.user = app.currentUser.profile;
    //           state.customData = app.currentUser.customData;
    //           state.type = app.currentUser.providerType;
    //           state.error = null;
    //         }
    //         //authRedirectUrl
    //         const authRedirectUrl = localStorage.getItem("authRedirectUrl");
    //         // get return url from location state or default to home page
    //         const { from } = history.location.state || {
    //           // from: { pathname: '/' },
    //           from: authRedirectUrl ? authRedirectUrl : "/",
    //         };
    //         if (from) {
    //           history.navigate(from);
    //         }
    //       })
    //       .addCase(rejected, (state: IUserStore, action: { error: any }) => {
    //         state.error = action.error;
    //       });
    //   }
    //   function loginFacebook() {
    //     const { pending, fulfilled, rejected } = extraActions.loginFacebook;
    //     builder
    //       .addCase(pending, (state: IUserStore) => {
    //         state.error = null;
    //       })
    //       .addCase(fulfilled, (state: IUserStore, action: { payload: any }) => {
    //         if (app && app.currentUser) {
    //           app.currentUser.refreshCustomData();
    //           state.user = app.currentUser.profile;
    //           state.customData = app.currentUser.customData;
    //           state.type = app.currentUser.providerType;
    //           state.error = null;
    //         }
    //         const authRedirectUrl = localStorage.getItem("authRedirectUrl");
    //         // get return url from location state or default to home page
    //         const { from } = history.location.state || {
    //           // from: { pathname: '/' },
    //           from: authRedirectUrl ? authRedirectUrl : "/",
    //         };
    //         if (from) {
    //           history.navigate(from);
    //         }
    //       })
    //       .addCase(rejected, (state: IUserStore, action: { error: any }) => {
    //         state.error = action.error;
    //       });
    //   }
    //   function loginApple() {
    //     const { pending, fulfilled, rejected } = extraActions.loginApple;
    //     builder
    //       .addCase(pending, (state: IUserStore) => {
    //         state.error = null;
    //       })
    //       .addCase(fulfilled, (state: IUserStore, action: { payload: any }) => {
    //         if (app && app.currentUser) {
    //           app.currentUser.refreshCustomData();
    //           state.user = app.currentUser.profile;
    //           state.customData = app.currentUser.customData;
    //           state.type = app.currentUser.providerType;
    //           state.error = null;
    //         }
    //         const authRedirectUrl = localStorage.getItem("authRedirectUrl");
    //         // get return url from location state or default to home page
    //         const { from } = history.location.state || {
    //           // from: { pathname: '/' },
    //           from: authRedirectUrl ? authRedirectUrl : "/",
    //         };
    //         if (from) {
    //           history.navigate(from);
    //         }
    //       })
    //       .addCase(rejected, (state: IUserStore, action: { error: any }) => {
    //         state.error = action.error;
    //       });
    //   }
    //   function loginViaOTP() {
    //     const { pending, fulfilled, rejected } = extraActions.loginViaOTP;
    //     builder
    //       .addCase(pending, (state: IUserStore) => {
    //         state.error = null;
    //       })
    //       .addCase(fulfilled, (state: IUserStore, action: { payload: any }) => {
    //         if (app && app.currentUser) {
    //           app.currentUser.refreshCustomData();
    //           state.user = app.currentUser.profile;
    //           state.customData = app.currentUser.customData;
    //           state.type = app.currentUser.providerType;
    //           state.error = null;
    //         }
    //         const authRedirectUrl = localStorage.getItem("authRedirectUrl");
    //         // get return url from location state or default to home page
    //         const { from } = history.location.state || {
    //           // from: { pathname: '/' },
    //           from: authRedirectUrl ? authRedirectUrl : "/",
    //         };
    //         if (from) {
    //           history.navigate(from);
    //         }
    //       })
    //       .addCase(rejected, (state: IUserStore, action: { error: any }) => {
    //         state.error = action.error;
    //       });
    //   }
  };
}
