import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  useBranches,
  useCurrentProvider,
} from "../../../Common/Hooks/queryCalls";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import AddBranch from "./AddBranch";
import ViewBranch from "./ViewBranch";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { deleteBranch } from "../../../Services/dbService";
import { LottieLoading } from "../../../Common/LottieLoading";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

const headCells = [
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "number",
    numeric: false,
    disablePadding: false,
    label: "Number",
  },
  {
    id: "isactive",
    numeric: false,
    disablePadding: false,
    label: "Is Active",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            // sortDirection={orderBy === headCell.id ? order : false}
            sx={tableHeadStyle}
            style={{ fontFamily: "outfit" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const Branches = () => {
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [flag, setFlag] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [specificProvider, setSpecificProvider] = useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [tabsOpen, setTabsOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});

  const selectedAddress = localStorage.getItem("mapAddress");
  const selectedGeoMarker = localStorage.getItem("mapGeoCode");
  //console.log(selectedAddress, selectedGeoMarker);

  useEffect(() => {
    if (selectedAddress) {
      setOpen(true);
    }
  }, []);
  //console.log("edit:", edit);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEdit(true);
    setSpecificProvider({});
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (item) => {
    // localStorage.setItem("editAddressItem", JSON.stringify(item));
    localStorage.setItem("_id", item?._id);
    localStorage.setItem("englishName", item?.name?.en);
    localStorage.setItem("arabicName", item?.name?.ar);
    localStorage.setItem("email", item?.email);
    localStorage.setItem("number", item?.number);
    localStorage.setItem("username", item?.username);
    localStorage.setItem("prefix", item?.prefix);
    // localStorage.setItem("city", item?.city);
    setSpecificProvider(item);
    setOpen(true);
    setEdit(true);
  };

  const handleTabsOpen = (item) => {
    setSpecificProvider(item);
    setTabsOpen(true);
  };

  const handleTabsClose = () => {
    setTabsOpen(false);
    setSpecificProvider({});
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteItem({});
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const currentProvider = useCurrentProvider();
  // const providerId = "5eda1de77e3e21512c21940f";
  const { data, isLoading, refetch } = useBranches({
    providerId: currentProvider?.data?._id,
  });
  //console.log("branches::", data);
  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    );
  }
  // if (!data) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       Loading
  //     </h1>
  //   );
  // }

  // if (data?.length == 0) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       No Items Available
  //     </h1>
  //   );
  // }

  const handleClickDelete = async () => {
    setDeleteLoading(true);
    //console.log("item going to be deleted::", deleteItem);
    const result = await deleteBranch({
      providerId: currentProvider?.data?._id,
      branchId: deleteItem?._id,
    });
    //console.log("branch deleted successfully:-", result);
    if (result) {
      refetch();
      setOpenDialog(false);
      setDeleteLoading(false);
    }
  };

  //console.log("specificProvider::", specificProvider);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "outfit" }}>
          {"Are you sure you want to delete this branch?"}
        </DialogTitle>

        <DialogActions>
          {!deleteLoading ? (
            <Button
              onClick={handleCloseDialog}
              style={{ fontFamily: "outfit" }}
            >
              No
            </Button>
          ) : (
            ""
          )}
          {deleteLoading ? (
            <LoadingButton
              size="small"
              color="secondary"
              // onClick={handleClick}
              loading={deleteLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              <span>Yes</span>
            </LoadingButton>
          ) : (
            <Button
              onClick={handleClickDelete}
              autoFocus
              style={{ fontFamily: "outfit" }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "80%",
            },
          }}
        >
          <AddBranch
            providerId={currentProvider?.data?._id}
            setOpen={setOpen}
            edit={edit}
            specificProvider={specificProvider}
            refetch={refetch}
            setEdit={setEdit}
          />
        </Box>
      </Modal>

      <Modal open={tabsOpen} onClose={handleTabsClose}>
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              // p:2,
              overflow: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 2,
              overflow: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 2,
              overflow: "scroll",
              maxHeight: "80%",
            },
          }}
        >
          <ViewBranch specificProvider={specificProvider} />
        </Box>
      </Modal>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "5px",
        }}
      >
        <Button
          sx={{
            "@media (max-width: 600px)": {
              fontSize: "0.6rem",
              backgroundColor: "#2b87ae",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              fontSize: "0.8rem",
              backgroundColor: "#2b87ae",
            },
            "@media (min-width: 970px)": {
              fontSize: "0.8rem",
              backgroundColor: "#2b87ae",
            },
          }}
          variant="contained"
          onClick={() => {
            {
              setOpen(true);
              setEdit(false);
            }
          }}
          style={{ fontFamily: "outfit" }}
        >
          Add New Branch
        </Button>
      </div>
      <div>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  // numSelected={selected.length}
                  // order={order}
                  // orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  // onRequestSort={handleRequestSort}
                  rowCount={data?.length}
                />
                {data?.length == 0 && (
                  <caption style={{ fontFamily: "outfit" }}>
                    No Items Available
                  </caption>
                )}
                <TableBody>
                  {data
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((item, index) => {
                      // const isItemSelected = isSelected(row.name);
                      // const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.name)}
                          // role="checkbox"
                          // aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={item._id}
                          // selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell
                            component="th"
                            // id={labelId}
                            scope="row"
                            padding="none"
                            align="center"
                            sx={tableContentStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            {item?.name?.en}
                          </TableCell>
                          <TableCell
                            sx={tableContentStyle}
                            align="center"
                            style={{ fontFamily: "outfit" }}
                          >
                            {item?.username}
                          </TableCell>
                          <TableCell
                            sx={tableContentStyle}
                            align="center"
                            style={{ fontFamily: "outfit" }}
                          >
                            {item?.email}
                          </TableCell>
                          <TableCell
                            sx={tableContentStyle}
                            align="center"
                            style={{ fontFamily: "outfit" }}
                          >
                            {item?.number}
                          </TableCell>
                          <TableCell align="center">
                            {item?.metadata?.isActive ? (
                              <CheckCircleIcon fontSize="small" />
                            ) : (
                              <CancelIcon fontSize="small" />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  justifyContent: "space-around",
                                }}
                              >
                                <IconButton
                                  onClick={() => handleTabsOpen(item)}
                                >
                                  <VisibilityIcon fontSize="small" />
                                </IconButton>
                                <IconButton onClick={() => handleEdit(item)}>
                                  <EditNoteIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setOpenDialog(true);
                                    setDeleteItem(item);
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </div>
                            }
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              sx={tableContentStyle}
              rowsPerPageOptions={[3, 5, 10]}
              component="div"
              count={data?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>
    </>
  );
};

export default Branches;

const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.775rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.875rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.875rem",
  },
};

const tableContentStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.65rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.75rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.75rem",
  },
};
