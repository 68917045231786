import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { useOneOrderDetails } from "../../../Common/Hooks/queryCalls";
import "../../../css/orders.css";
import { formatDate, formatCurrency } from "../../../Services/functions";
import ListGroup from "react-bootstrap/ListGroup";
import { useHistory } from "react-router-dom";
import {
  acceptOrder,
  OrderStatusUpdate,
  DownloadAwb,
} from "../../../Services/dbService";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { LottieLoading } from "../../../Common/LottieLoading";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const OrderDetail = () => {
  const [loading, setLoading] = React.useState(false);
  const [awbLoading, setAwbLoading] = React.useState(false);
  const history = useHistory();
  const { id } = useParams();
  const { data, isLoading, refetch } = useOneOrderDetails({ orderId: id });
  // console.log("order details data::", data);
  // console.log(id);
  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    );
  }
  if (!data) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    );
  }
  const handleAcceptOrder = async () => {
    // console.log("acceptedf");
    setLoading(true);
    const result = await acceptOrder({
      id: id.toString(),
    });
    if (result) {
      refetch();
      setLoading(false);
    }
    // console.log("accept result::", result);
    refetch();
  };
  const handleOrderStatusUpdate = async ({ itemId, orderStatus }) => {
    // console.log(orderStatus);
    setLoading(true);
    const result = await OrderStatusUpdate({ itemId, orderStatus, data });
    if (result) {
      refetch();
      setLoading(false);
    }
    // console.log("OrderStatusUpdate result::", result);
    refetch();
  };

  const handleDownloadAWb = async () => {
    // console.log(orderStatus);
    setAwbLoading(true);
    DownloadAwb({ data })
      .then((response) => response.blob())
      .then((result) => {
        // console.log("Result", result);
        setAwbLoading(false);
        const blob = new Blob([result], { type: "application/pdf" });
        var file = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = file;
        a.download = "AWB";
        document.body.appendChild(a);
        a.click();
        window.onfocus = function () {
          document.body.removeChild(a);
        };
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <div className="order-details">
        <div>
          <Button
            sx={{
              "@media (max-width: 600px)": {
                fontSize: "0.675rem",
                marginBottom: 1,
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "0.775rem",
                marginBottom: 1,
              },
              "@media (min-width: 970px)": {
                fontSize: "0.775rem",
                marginBottom: 1,
              },
            }}
            style={{ fontFamily: "outfit" }}
            variant="outlined"
            onClick={() => {
              history.push(`/orders`);
            }}
          >
            Back
          </Button>
        </div>
        <ListGroup>
          <ListGroup.Item>
            <div className="order-detail-row" style={{ fontFamily: "outfit" }}>
              <div className="order-detail-element">
                <h4>Reference:</h4>
                <h4>{data?.refNumber}</h4>
              </div>
              <div className="order-detail-element">
                <h4>Date:</h4>
                <h4>{formatDate(data?.date)}</h4>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="order-detail-row" style={{ fontFamily: "outfit" }}>
              <div className="order-detail-element">
                <h4>Name:</h4>
                <h4>{`${data?.customer?.firstName} ${data?.customer?.lastName}`}</h4>
              </div>
              <div className="order-detail-element">
                <h4>Mobile:</h4>
                <h4>{`+971 ${data?.customer?.number}`}</h4>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="order-detail-row" style={{ fontFamily: "outfit" }}>
              <div className="order-detail-element">
                <h4>orderType:</h4>
                <h4>{data?.orderType}</h4>
              </div>
              <div className="order-detail-element">
                <h4>orderStatus:</h4>
                <h4>{data?.status}</h4>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="order-detail-row" style={{ fontFamily: "outfit" }}>
              <div className="order-detail-element">
                <h4>Special Note:</h4>
                <h4>{data?.notes}</h4>
              </div>
              <div className="order-detail-element">
                <h4>Total Amount:</h4>
                <h4>{formatCurrency(data?.amount)}</h4>
              </div>
            </div>
          </ListGroup.Item>
          {data?.status == "ready" ? (
            <ListGroup.Item>
              <div
                className="order-detail-row"
                style={{ fontFamily: "outfit" }}
              >
                <div className="order-detail-element">
                  <h4>Download the AWB Document:</h4>
                  <h4></h4>
                </div>
                <div className="order-detail-element">
                  <h4>
                    {" "}
                    {awbLoading ? (
                      <LoadingButton
                        size="small"
                        color="secondary"
                        // onClick={handleClick}
                        loading={awbLoading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                      >
                        <span> Download AWb</span>
                      </LoadingButton>
                    ) : (
                      <Button
                        sx={{
                          "@media (max-width: 600px)": {
                            fontSize: "0.6rem",
                            width: 150,
                          },
                          "@media (min-width: 601px) and (max-width: 960px)": {
                            fontSize: "0.8rem",
                            width: 180,
                          },
                          "@media (min-width: 970px)": {
                            fontSize: "0.8rem",
                            width: 180,
                          },
                        }}
                        style={{ fontFamily: "outfit" }}
                        variant="contained"
                        onClick={() => handleDownloadAWb()}
                      >
                        Download AWb
                      </Button>
                    )}
                  </h4>
                  {/* <h4>{formatCurrency(data?.amount)}</h4> */}
                </div>
              </div>
            </ListGroup.Item>
          ) : (
            ""
          )}
          <ListGroup.Item>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={tableHeadStyle}
                      align="left"
                      style={{ fontFamily: "outfit" }}
                    >
                      Item
                    </StyledTableCell>
                    <StyledTableCell
                      sx={tableHeadStyle}
                      align="center"
                      style={{ fontFamily: "outfit" }}
                    >
                      Quantity
                    </StyledTableCell>
                    <StyledTableCell
                      sx={tableHeadStyle}
                      align="center"
                      style={{ fontFamily: "outfit" }}
                    >
                      Add Ons
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.items.map((row) => (
                    <StyledTableRow key={row?._id}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={tableContentStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {`${row?.item?.name?.en || row?.name?.en}(${
                          row?.item?.name?.ar || row?.name?.ar
                        })`}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                        sx={tableContentStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {row?.quantity}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                        sx={tableContentStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {row?.modifiers?.map((value, index) => (
                          // console.log(value?.modifierName?.en)
                          <h6
                            className="order-addon-h6"
                            key={index}
                          >{`${value?.modifierName?.en},`}</h6>
                        ))}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ListGroup.Item>{" "}
          <ListGroup.Item>
            <div className="order_update_btn_div">
              {data?.status == "pending" ? (
                loading ? (
                  <LoadingButton
                    size="small"
                    color="secondary"
                    // onClick={handleClick}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                  >
                    <span> Accept Order</span>
                  </LoadingButton>
                ) : (
                  <Button
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "0.6rem",
                        width: 200,
                      },
                      "@media (min-width: 601px) and (max-width: 960px)": {
                        fontSize: "0.8rem",
                        width: 250,
                      },
                      "@media (min-width: 970px)": {
                        fontSize: "0.8rem",
                        width: 250,
                      },
                    }}
                    style={{ fontFamily: "outfit" }}
                    variant="contained"
                    onClick={handleAcceptOrder}
                  >
                    Accept Order
                  </Button>
                )
              ) : (
                ""
              )}

              {data?.status == "accepted" ? (
                loading ? (
                  <LoadingButton
                    size="small"
                    color="secondary"
                    // onClick={handleClick}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                  >
                    <span> Mark As Ready</span>
                  </LoadingButton>
                ) : (
                  <Button
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "0.6rem",
                        width: 200,
                      },
                      "@media (min-width: 601px) and (max-width: 960px)": {
                        fontSize: "0.8rem",
                        width: 250,
                      },
                      "@media (min-width: 970px)": {
                        fontSize: "0.8rem",
                        width: 250,
                      },
                    }}
                    style={{ fontFamily: "outfit" }}
                    variant="contained"
                    onClick={() =>
                      handleOrderStatusUpdate({
                        itemId: id,
                        orderStatus: "ready",
                      })
                    }
                  >
                    Mark As Ready
                  </Button>
                )
              ) : (
                ""
              )}

              {data?.status == "ready" ? (
                loading ? (
                  <LoadingButton
                    size="small"
                    color="secondary"
                    // onClick={handleClick}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                  >
                    <span> Mark As Completed</span>
                  </LoadingButton>
                ) : (
                  <Button
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "0.6rem",
                        width: 200,
                      },
                      "@media (min-width: 601px) and (max-width: 960px)": {
                        fontSize: "0.8rem",
                        width: 250,
                      },
                      "@media (min-width: 970px)": {
                        fontSize: "0.8rem",
                        width: 250,
                      },
                    }}
                    style={{ fontFamily: "outfit" }}
                    variant="outlined"
                    onClick={() =>
                      handleOrderStatusUpdate({
                        itemId: id,
                        orderStatus: "completed",
                      })
                    }
                  >
                    Mark As Completed
                  </Button>
                )
              ) : (
                ""
              )}
            </div>
          </ListGroup.Item>
        </ListGroup>
      </div>
    </>
  );
};

export default OrderDetail;

const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.775rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.875rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.875rem",
  },
};

const tableContentStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.65rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.75rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.75rem",
  },
};
