import { lazy, Suspense, useEffect } from "react";
/// Components
import Index from "./jsx/index";
// import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import Login from "./jsx/pages/Login";
import SignUp from "./jsx/pages/Registration";
import ForgotPassword from "./jsx/pages/ForgotPassword";
import ConfirmPassword from "./jsx/pages/ConfirmResetPassword";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { app } from "./Services/dbService";
import { useCurrentProvider } from "./Common/Hooks/queryCalls";

// // action
// import { checkAutoLogin } from "./services/AuthService";
// import { isAuthenticated } from "./store/selectors/AuthSelectors";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import StripeWindow from "./jsx/pages/StripeWindow";

function App(props) {
  const {
    data: provider,
    isLoading: isProviderLoading,
    refetch,
  } = useCurrentProvider();
  //console.log("provider::", provider);
  const authType = useSelector((x) => x.auth.type);
  const location = useLocation();
  const history = useHistory();
  // console.log("current pathname:", location.pathname);
  // console.log(location.pathname === "/login");
  // if (authType === ' ' || 'anon-user')
  // return (
  //   <>
  //     <Navigate to="/login" />
  //   </>
  // );
  //   const dispatch = useDispatch();
  //   useEffect(() => {
  //     checkAutoLogin(dispatch, props.history);
  //   }, [dispatch, props.history]);

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/page-register" component={SignUp} />
      <Route path="/page-forgot-password" component={ForgotPassword} />
      <Route path="/page-confirm-password" component={ConfirmPassword} />
      <Route path="/page-subscription/:type" component={StripeWindow} />
      <Route path="/page-subscription/:type/:status" component={StripeWindow} />
    </Switch>
  );
  // console.log("currentUser::",app.currentUser)
  if (
    !app.currentUser ||
    (app.currentUser && app.currentUser.providerType !== "local-userpass")
  ) {
    if (location.pathname === "/page-confirm-password") {
      return (
        <>
          {/* <Redirect to="/login" state={{ from: location }} /> */}

          {/* history.push("/login"); */}
          {routes}
        </>
      );
    } else {
      return (
        <>
          <Redirect to="/login" state={{ from: location }} />

          {/* history.push("/login"); */}
          {routes}
        </>
      );
    }
  } else {
    if (
      provider &&
      (!provider?.subscription ||
        (provider?.subscription && provider?.subscription?.status != "active"))
    ) {
      // return history.push("/page-subscription/payment/initialize");
      return (
        <>
          <Redirect
            to="/page-subscription/payment/initialize"
            state={{ from: location }}
          />
          {/* history.push("/login"); */}
          {routes}
        </>
      );
    } else {
      return (
        <>
          <Suspense
            fallback={
              <div id="preloader">
                <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              </div>
            }
          >
            {/* <Redirect to="/dashboard" state={{ from: location }} /> */}
            <Index />
          </Suspense>
        </>
      );
    }

    // return (
    //   <>
    //     <Suspense
    //       fallback={
    //         <div id="preloader">
    //           <div className="sk-three-bounce">
    //             <div className="sk-child sk-bounce1"></div>
    //             <div className="sk-child sk-bounce2"></div>
    //             <div className="sk-child sk-bounce3"></div>
    //           </div>
    //         </div>
    //       }
    //     >
    //       {/* <Redirect to="/dashboard" state={{ from: location }} /> */}
    //       <Index />
    //     </Suspense>
    //   </>
    // );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     isAuthenticated: isAuthenticated(state),
//   };
// };

export default App;
