import React, { useEffect, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import "../../../css/dashboard-details.css";
import { updateProvider } from "../../../Services/dbService";
import {
  useCurrentProvider,
  useProvider,
} from "../../../Common/Hooks/queryCalls";
import {
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
  FormText,
  InputGroup,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Modal from "react-bootstrap/Modal";
import { IKContext, IKImage, IKUpload } from "imagekitio-react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Alert } from "react-bootstrap";
import {
  urlEndpoint,
  publicKey,
  authenticationEndpoint,
} from "../../../Common/ImageKit/ImageKitCred";
import { LottieLoading } from "../../../Common/LottieLoading";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

const Details = () => {
  const [loading, setLoading] = React.useState(false);
  const [resIcon, setResIcon] = useState({});
  const [resIconLoading, setResIconLoading] = useState(false);
  const [resBanner, setResBanner] = useState({});
  const [resBannerLoading, setResBannerLoading] = useState(false);
  const [isHoveredIcon, setIsHoveredIcon] = useState(false);
  const [isHoveredBanner, setIsHoveredBanner] = useState(false);
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const validationSchema = yup.object().shape({
    englishName: yup.string().required(),
    arabicName: yup.string().required(),
    email: yup.string().email().required(),
    englishBio: yup.string().required(),
    arabicBio: yup.string().required(),
    number: yup.string().required(),
    username: yup.string().required(),
  });

  //juices and burgers provider
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setValue("englishName", data?.name?.en);
    setValue("arabicName", data?.name?.ar);
    setValue("email", data?.email);
    setValue("englishBio", data?.description?.en);
    setValue("arabicBio", data?.description?.ar);
    setValue("number", data?.number);
    setValue("username", data?.username);
    setValue("primaryColor", data?.primaryColor);
    setValue("secondaryColor", data?.secondaryColor);
    setValue("cartTextColor", data?.cartTextColor);
    setValue("primaryTextColor", data?.primaryTextColor);
    setValue("secondaryTextColor", data?.secondaryTextColor);
    setValue("borderColor", data?.borderColor);
    setValue("headerBackgroundColor", data?.headerBackgroundColor);
    setValue("pageBackgorundColor", data?.pageBackgorundColor);
    setResIcon(data?.icon);
    setResBanner(data?.mainImage);
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const currentProvider = useCurrentProvider();
  //console.log("currentProvider:", currentProvider);

  // const providerId = "5eda1de77e3e21512c21940f";
  const { data, isLoading, refetch } = useProvider({
    providerId: currentProvider?.data?._id,
  });
  if (isLoading) {
    return (
      <h1>
        <LottieLoading />
      </h1>
    );
  }
  if (data?.length == 0) {
    return <h1>No Items Available</h1>;
  }
  //console.log(" dasboard data::", data);

  const onSubmit = async (data) => {
    //console.log(data);
    setLoading(true);
    const result = await updateProvider({
      providerId: currentProvider?.data?._id,
      data,
      resIcon,
      resBanner,
    });
    if (result) {
      refetch();
      setShowSuccess(true);
      setShow(false);
      setLoading(false);
    }

    //console.log("formData:", data);
    //console.log("Provider updated successfully:", result);
  };

  const onErrorIcon = (err) => {
    //console.log("ErrorIcon::", err);
  };

  const onSuccessIcon = (res) => {
    //console.log("SuccessIcon", res);
    setResIcon(res);
    setResIconLoading(false);
  };
  const onUploadProgressIcon = (progress) => {
    //console.log("Progress", progress);
    setResIconLoading(true);
  };

  const onErrorBanner = (err) => {
    //console.log("ErrorBanner", err);
  };

  const onSuccessBanner = (res) => {
    //console.log("SuccessBanner", res);
    setResBanner(res);
    setResBannerLoading(false);
  };

  const onUploadProgressBanner = (progress) => {
    //console.log("Progress", progress);
    setResBannerLoading(true);
  };

  if (showSuccess) {
    setInterval(() => setShowSuccess(false), 5000);
  }

  return (
    <>
      <div>
        {/* <div >
        <h4 className="title-providername">{`Hi,${provider?.name?.en}(${provider?.name?.ar})`}</h4>
      </div> */}
        <Modal show={show} onHide={handleClose} size="lg">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm your Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormGroup>
                <FormLabel>Email ID</FormLabel>
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => <FormControl {...field} />}
                />
                {errors.email && <span>{errors.email.message}</span>}
              </FormGroup>
              <FormGroup>
                <FormLabel>Name of your business(in English)</FormLabel>
                <Controller
                  control={control}
                  name="englishName"
                  render={({ field }) => <FormControl {...field} />}
                />
                {errors.englishName && (
                  <span>{errors.englishName.message}</span>
                )}
              </FormGroup>
              <FormGroup>
                <FormLabel>Name of your business(in Arabic)</FormLabel>
                <Controller
                  control={control}
                  name="arabicName"
                  render={({ field }) => <FormControl {...field} />}
                />
                {errors.arabicName && <span>{errors.arabicName.message}</span>}
              </FormGroup>
              <FormGroup>
                <FormLabel>Short Bio about the business(in English)</FormLabel>
                <Controller
                  control={control}
                  name="englishBio"
                  render={({ field }) => <FormControl {...field} />}
                />
                {errors.englishBio && <span>{errors.englishBio.message}</span>}
              </FormGroup>
              <FormGroup>
                <FormLabel>Short Bio about the business(in Arabic)</FormLabel>
                <Controller
                  control={control}
                  name="arabicBio"
                  render={({ field }) => <FormControl {...field} />}
                />
                {errors.arabicBio && <span>{errors.arabicBio.message}</span>}
              </FormGroup>
              <FormGroup>
                <FormLabel>Mobile Number</FormLabel>
                <InputGroup>
                  <InputGroup.Text>+971</InputGroup.Text>
                  <Controller
                    control={control}
                    name="number"
                    render={({ field }) => <FormControl {...field} />}
                  />
                </InputGroup>

                {errors.number && <span>{errors.number.message}</span>}
              </FormGroup>
              <FormGroup>
                <FormLabel>Username</FormLabel>
                <Controller
                  control={control}
                  name="username"
                  render={({ field }) => <FormControl {...field} />}
                />
                {errors.username && <span>{errors.username.message}</span>}
              </FormGroup>
              <FormText>
                {" "}
                This is the main username from where all your branches will be
                mananged
              </FormText>
              <div className="imageKit-div">
                <FormGroup className="each-Image-kit">
                  <FormLabel>Add your provider icon :</FormLabel>
                  <Controller
                    control={control}
                    name="icon"
                    render={({ field }) => (
                      <div
                        className="col-md-6"
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        {resIcon && resIcon.filePath ? (
                          <div
                            style={{
                              width: 35,
                              height: 30,
                              display: "flex",
                              alignItems: "center",
                              marginTop: 10,
                            }}
                            onMouseEnter={() => setIsHoveredIcon(true)}
                            onMouseLeave={() => setIsHoveredIcon(false)}
                          >
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <IKImage
                                urlEndpoint={urlEndpoint}
                                path={resIcon.filePath}
                                width="50px"
                                height="50px"
                              />
                              {resIcon.filePath && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    zIndex: "1",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    // setImage(null);
                                    setResIcon(null);
                                  }}
                                >
                                  <AiOutlineCloseCircle
                                    size={16}
                                    color="red"
                                    style={{
                                      position: "absolute",
                                      top: -10,
                                      right: -10,
                                      // color: 'red',
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <IKContext
                            publicKey={publicKey}
                            urlEndpoint={urlEndpoint}
                            authenticationEndpoint={authenticationEndpoint}
                          >
                            {resIconLoading ? (
                              <Box sx={{ display: "flex" }}>
                                <CircularProgress />
                              </Box>
                            ) : (
                              <IKUpload
                                onError={onErrorIcon}
                                onSuccess={onSuccessIcon}
                                onUploadStart={onUploadProgressIcon}
                                buttonProps={{ children: "+" }}
                              />
                            )}
                          </IKContext>
                        )}
                      </div>
                    )}
                  />
                  {errors.icon && <span>{errors.icon.message}</span>}
                </FormGroup>

                <FormGroup className="each-Image-kit">
                  <FormLabel>Add your provider banner :</FormLabel>
                  <Controller
                    control={control}
                    name="banner"
                    render={({ field }) => (
                      <div
                        className="col-md-6"
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        {resBanner && resBanner.filePath ? (
                          <div
                            style={{
                              width: 35,
                              height: 30,
                              display: "flex",
                              alignItems: "center",
                              marginTop: 10,
                            }}
                            onMouseEnter={() => setIsHoveredBanner(true)}
                            onMouseLeave={() => setIsHoveredBanner(false)}
                          >
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <IKImage
                                urlEndpoint={urlEndpoint}
                                path={resBanner.filePath}
                                width="50px"
                                height="50px"
                              />
                              {resBanner.filePath && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    zIndex: "1",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    // setImage(null);
                                    setResBanner(null);
                                  }}
                                >
                                  <AiOutlineCloseCircle
                                    size={16}
                                    color="red"
                                    style={{
                                      position: "absolute",
                                      top: -10,
                                      right: -10,
                                      // color: 'red',
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <IKContext
                            publicKey={publicKey}
                            urlEndpoint={urlEndpoint}
                            authenticationEndpoint={authenticationEndpoint}
                          >
                            {resBannerLoading ? (
                              <Box sx={{ display: "flex" }}>
                                <CircularProgress />
                              </Box>
                            ) : (
                              <IKUpload
                                onError={onErrorBanner}
                                onSuccess={onSuccessBanner}
                                onUploadStart={onUploadProgressBanner}
                                buttonProps={{ children: "+" }}
                              />
                            )}
                          </IKContext>
                        )}
                      </div>
                    )}
                  />
                  {errors.banner && <span>{errors.banner.message}</span>}
                </FormGroup>
              </div>

              <div className="form-color-div">
                <FormGroup className="form-color">
                  <FormLabel>Primary Color</FormLabel>
                  <Controller
                    control={control}
                    name="primaryColor"
                    render={({ field }) => (
                      <input
                        className="form-color-input"
                        type="color"
                        {...field}
                        value={field.value || "#000000"} // set default color value to black
                      />
                    )}
                  />
                  {errors.primaryColor && (
                    <span>{errors.primaryColor.message}</span>
                  )}
                </FormGroup>

                <FormGroup className="form-color">
                  <FormLabel> Secondary Color</FormLabel>
                  <Controller
                    control={control}
                    name="secondaryColor"
                    render={({ field }) => (
                      <input
                        className="form-color-input"
                        type="color"
                        {...field}
                        value={field.value || "#000000"} // set default color value to black
                      />
                    )}
                  />
                  {errors.secondaryColor && (
                    <span>{errors.secondaryColor.message}</span>
                  )}
                </FormGroup>

                <FormGroup className="form-color">
                  <FormLabel>Cart Text Color</FormLabel>
                  <Controller
                    control={control}
                    name="cartTextColor"
                    render={({ field }) => (
                      <input
                        className="form-color-input"
                        type="color"
                        {...field}
                        value={field.value || "#000000"} // set default color value to black
                      />
                    )}
                  />
                  {errors.cartTextColor && (
                    <span>{errors.cartTextColor.message}</span>
                  )}
                </FormGroup>

                <FormGroup className="form-color">
                  <FormLabel>Primary Text Color</FormLabel>
                  <Controller
                    control={control}
                    name="primaryTextColor"
                    render={({ field }) => (
                      <input
                        className="form-color-input"
                        type="color"
                        {...field}
                        value={field.value || "#000000"} // set default color value to black
                      />
                    )}
                  />
                  {errors.primaryTextColor && (
                    <span>{errors.primaryTextColor.message}</span>
                  )}
                </FormGroup>

                <FormGroup className="form-color">
                  <FormLabel>Secondary Text Color</FormLabel>
                  <Controller
                    control={control}
                    name="secondaryTextColor"
                    render={({ field }) => (
                      <input
                        className="form-color-input"
                        type="color"
                        {...field}
                        value={field.value || "#000000"} // set default color value to black
                      />
                    )}
                  />
                  {errors.secondaryTextColor && (
                    <span>{errors.secondaryTextColor.message}</span>
                  )}
                </FormGroup>

                <FormGroup className="form-color">
                  <FormLabel> Border Color</FormLabel>
                  <Controller
                    control={control}
                    name="borderColor"
                    render={({ field }) => (
                      <input
                        className="form-color-input"
                        type="color"
                        {...field}
                        value={field.value || "#000000"} // set default color value to black
                      />
                    )}
                  />
                  {errors.borderColor && (
                    <span>{errors.borderColor.message}</span>
                  )}
                </FormGroup>

                <FormGroup className="form-color">
                  <FormLabel>Header Background Color</FormLabel>
                  <Controller
                    control={control}
                    name="headerBackgroundColor"
                    render={({ field }) => (
                      <input
                        className="form-color-input"
                        type="color"
                        {...field}
                        value={field.value || "#000000"} // set default color value to black
                      />
                    )}
                  />
                  {errors.headerBackgroundColor && (
                    <span>{errors.headerBackgroundColor.message}</span>
                  )}
                </FormGroup>

                <FormGroup className="form-color">
                  <FormLabel>Page Background Color</FormLabel>
                  <Controller
                    control={control}
                    name="pageBackgorundColor"
                    render={({ field }) => (
                      <input
                        className="form-color-input"
                        type="color"
                        {...field}
                        value={field.value || "#000000"} // set default color value to black
                      />
                    )}
                  />
                  {errors.pageBackgorundColor && (
                    <span>{errors.pageBackgorundColor.message}</span>
                  )}
                </FormGroup>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {!loading ? (
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              ) : (
                ""
              )}
              {loading ? (
                <LoadingButton
                  // size="small"
                  color="secondary"
                  // onClick={handleClick}
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                >
                  <span>Save</span>
                </LoadingButton>
              ) : (
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
        {showSuccess && (
          <Alert
            variant="success"
            onClose={() => setShowSuccess(false)}
            dismissible
          >
            Provider Updated Successfully!
          </Alert>
        )}
        <div className="basic-details-button">
          <Button
            variant="primary"
            style={{
              color: "white",
              backgroundColor: "#2b87ae",
              borderColor: "#2b87ae",
              ":hover": {
                backgroundColor: "#2b87ae",
                color: "white",
              },
            }}
            onClick={handleShow}
          >
            Edit
          </Button>
        </div>
        <ListGroup>
          <ListGroup.Item>
            <div className="listgroupitem-row">
              <div className="eachitem-div">
                <h4>Name(EN):</h4>
                <h4>{data?.name?.en}</h4>
              </div>
              <div className="eachitem-div">
                <h4>Name(AR):</h4>
                <h4>{data?.name?.ar}</h4>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="listgroupitem-row">
              <div className="eachitem-div">
                <h4>Description(EN):</h4>
                <h4 style={{ width: " 350px" }}>{data?.description?.en}</h4>
              </div>
              <div className="eachitem-div">
                <h4>Description(AR):</h4>
                <h4 style={{ width: " 350px" }}>{data?.description?.ar}</h4>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="listgroupitem-row">
              <div className="eachitem-div">
                <h4>Number:</h4>
                <h4>+971{data?.number?.replace("+971", "")}</h4>
              </div>
              <div className="eachitem-div">
                <h4>Email:</h4>
                <h4>{data?.email}</h4>
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="listgroupitem-row">
              <div className="eachitem-div">
                <h4>Icon:</h4>
                <img
                  style={{ width: "50px", height: "50px" }}
                  src={data?.icon?.url}
                  alt="image not available"
                />
              </div>
              <div className="eachitem-div">
                <h4>Banner:</h4>
                <img
                  style={{ width: "50px", height: "50px" }}
                  src={data?.mainImage?.url}
                  alt="image not available"
                />
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="listgroupitem-row">
              <div className="eachitem-div">
                <h4>Primary Color:</h4>
                <input type="color" disabled value={data?.primaryColor} />
              </div>
              <div className="eachitem-div">
                <h4>Secondary Color:</h4>
                <input type="color" disabled value={data?.secondaryColor} />
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="listgroupitem-row">
              <div className="eachitem-div">
                <h4>Cart Text Color:</h4>
                <input type="color" disabled value={data?.cartTextColor} />
              </div>
              <div className="eachitem-div">
                <h4>Primary Text Color:</h4>
                <input type="color" disabled value={data?.primaryTextColor} />
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="listgroupitem-row">
              <div className="eachitem-div">
                <h4>Secondary Text Color:</h4>
                <input type="color" disabled value={data?.secondaryTextColor} />
              </div>
              <div className="eachitem-div">
                <h4>Border Color:</h4>
                <input type="color" disabled value={data?.borderColor} />
              </div>
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="listgroupitem-row">
              <div className="eachitem-div">
                <h4>Header Background Color:</h4>
                <input
                  type="color"
                  disabled
                  value={data?.headerBackgroundColor}
                />
              </div>
              <div className="eachitem-div">
                <h4>Page Background Color:</h4>
                <input
                  type="color"
                  disabled
                  value={data?.pageBackgroundColor}
                />
              </div>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </div>
    </>
  );
};

export default Details;

// {

// primaryColor;
// ("rgb(97, 158, 195)");
// secondaryColor;
// ("#e2222b");
// darkModeEnabled;
// true;
// stripeTheme;
// ("night");
// cartTextColor;
// ("#CCC");
// headerBackgroundColor;
// ("#333");
// pageBackgroundColor;
// ("#333");
// primaryTextColor;
// ("#FFF");
// secondaryTextColor;
// ("#CCC");
// borderColor;
// ("rgb(70, 70, 70)");

// }
