import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Typography, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "../../../css/branches.css";
import InputAdornment from "@mui/material/InputAdornment";
import { addBranch, updateBranch } from "../../../Services/dbService";
import {
  usePrefixCheck,
  useBranchCity,
  useBranchCitySetting,
} from "../../../Common/Hooks/queryCalls";
import Maps from "../Maps/Maps";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import Autocomplete from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
const AddBranch = ({
  providerId,
  setOpen,
  edit,
  specificProvider = null,
  refetch,
  setEdit,
}) => {
  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const [cityId, setCityId] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [geoCode, setGeoCode] = useState();
  const [checkPrefix, setCheckPrefix] = useState("");
  const [checkNumber, setCheckNumber] = useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const prefixCheck = usePrefixCheck({ fieldValue: checkPrefix });
  const numberCheck = usePrefixCheck({ fieldValue: checkNumber });
  const { data, isLoading } = useBranchCity();
  const citySetting = useBranchCitySetting({ cityId });
  const [showNumber, setShowNumber] = React.useState(false);

  //console.log("fetched", citySetting?.data);

  const selectedAddress = localStorage.getItem("mapAddress");
  const selectedGeoMarker = JSON.parse(localStorage.getItem("mapGeoCode"));

  const editenglishName = localStorage.getItem("englishName");
  const editarabicName = localStorage.getItem("arabicName");
  const editnumber = localStorage.getItem("number");
  const editemail = localStorage.getItem("email");
  const editusername = localStorage.getItem("username");
  const editprefix = localStorage.getItem("prefix");
  const editId = localStorage.getItem("_id");
  const editcity = localStorage.getItem("city");
  const editUpdate = localStorage.getItem("edit");

  // console.log({
  //   selectedAddress,
  //   editId,
  //   editcity,
  //   editenglishName,
  //   editarabicName,
  //   editnumber,
  //   editemail,
  //   editusername,
  //   editprefix,
  //   editUpdate,
  // });

  const validationSchema = Yup.object().shape({
    number: Yup.string().required("Number is required"),
    englishName: Yup.string().required(" Name is required"),
    arabicName: Yup.string().required("اسم  is required"),
    email: Yup.string().required("Email is required"),
    username: Yup.string().required("UserName is required"),
    prefix: Yup.string().required("Prefix is required"),
    branchAddress: Yup.string().required("Address is required"),
    // password: Yup.string().required("Address is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const englishName = watch("englishName");
  const arabicName = watch("arabicName");
  const email = watch("email");
  const number = watch("number");
  const username = watch("username");
  const prefix = watch("prefix");
  // const city = watch("city");
  const address = watch("branchAddress");
  async function addNewBranch(data) {
    try {
      //console.log("data::", data);
      if (edit) {
        // console.log("editBranch ", {
        //   providerId,
        //   itemId: editId,
        //   data,
        //   geoCode,
        //   cityId,
        //   showNumber,
        // });
        setLoading(true);
        const result = await updateBranch({
          providerId,
          itemId: editId,
          data,
          geoCode,
          cityId,
          showNumber,
        });
        // console.log("Editresult:", result);
        if (result) {
          setLoading(false);
          setOpen(false);
          refetch();
        }

        localStorage.removeItem("_id");
        localStorage.removeItem("englishName");
        localStorage.removeItem("arabicName");
        localStorage.removeItem("email");
        localStorage.removeItem("number");
        localStorage.removeItem("username");
        localStorage.removeItem("prefix");
        localStorage.removeItem("mapAddress");
        localStorage.removeItem("mapGeoCode");
        localStorage.removeItem("city");
        localStorage.removeItem("edit");
        //console.log("removed");
      } else {
        //console.log("addBranch ", data);
        setLoading(true);
        const result = await addBranch({
          providerId,
          data,
          geoCode,
          cityId,
          showNumber,
        });
        // console.log("Addresult:", result);
        if (result) {
          setLoading(false);
          setOpen(false);
          refetch();
        }

        localStorage.removeItem("_id");
        localStorage.removeItem("englishName");
        localStorage.removeItem("arabicName");
        localStorage.removeItem("email");
        localStorage.removeItem("number");
        localStorage.removeItem("username");
        localStorage.removeItem("prefix");
        localStorage.removeItem("mapAddress");
        localStorage.removeItem("mapGeoCode");
        localStorage.removeItem("city");
        localStorage.removeItem("edit");
        //console.log("removed");
      }
    } catch (error) {
      console.log("error:", error);
    }
  }

  const handleSwitchChange = () => {
    setShowNumber(!showNumber);
  };

  useEffect(() => {
    setValue(
      "branchAddress",
      specificProvider ? specificProvider?.address : ""
    );
    setValue("email", specificProvider ? specificProvider?.email : "");
    setValue("englishName", specificProvider ? specificProvider?.name?.en : "");
    setValue("arabicName", specificProvider ? specificProvider?.name?.ar : "");
    setValue("number", specificProvider ? specificProvider?.number : "");
    setValue("username", specificProvider ? specificProvider?.username : "");
    setValue("prefix", specificProvider ? specificProvider?.checkPrefix : "");
    setShowNumber(
      specificProvider?.showNumber != undefined
        ? specificProvider?.showNumber
        : null
    );
    setGeoCode({
      lng: specificProvider?.geo?.coordinates[0],
      lat: specificProvider?.geo?.coordinates[1],
    });

    // geo: {
    //   coordinates: [geoCode.lng, geoCode.lat],
    //   type: "Point",
    // },

    // setValue("city", specificProvider ? specificProvider?.city : "");
    setCityId(specificProvider ? specificProvider?.city : null);
  }, [specificProvider]);
  // console.log("specificProvider:", specificProvider);
  // console.log("geoCode:", geoCode);
  // console.log("lat:", specificProvider?.geo?.coordinates[1]);

  // console.log("cityId:", cityId);

  useEffect(() => {
    setValue("email", editemail ? editemail : "");
    setValue("englishName", editenglishName ? editenglishName : "");
    setValue("arabicName", editarabicName ? editarabicName : "");
    setValue("number", editnumber ? editnumber : "");
    setValue("username", editusername ? editusername : "");
    setValue("prefix", editprefix ? editprefix : "");
    setValue("branchAddress", selectedAddress);
    // setCityId(editcity ? editcity : null);
    setGeoCode(
      selectedGeoMarker
        ? selectedGeoMarker
        : {
            lng: specificProvider?.geo?.coordinates[0],
            lat: specificProvider?.geo?.coordinates[1],
          }
    );
    if (editUpdate === "true") {
      setEdit(true);
    }
    //console.log(edit);
  }, [selectedAddress]);

  useEffect(() => {
    setValue(
      "branchAddress",
      selectedAddress ? selectedAddress : specificProvider?.address
    );
  }, []);

  // console.log("typeof", typeof specificProvider?.address);

  // useEffect(() => {
  //   if (specificProvider) {
  //     setValue("email", specificProvider?.email);
  //     setValue("englishName", specificProvider?.name?.en);
  //     setValue("arabicName", specificProvider?.name?.ar);
  //     setValue("number", specificProvider?.number);
  //     setValue("username", specificProvider?.username);
  //     setValue("prefix", specificProvider?.prefix);
  //     setValue("address", specificProvider?.address);
  //     setValue("city", specificProvider?.city);
  //   } else if (editAddressItem) {
  //     setValue("email", editAddressItem?.email);
  //     setValue("englishName", editAddressItem?.name?.en);
  //     setValue("arabicName", editAddressItem?.name?.ar);
  //     setValue("number", editAddressItem?.number);
  //     setValue("username", editAddressItem?.username);
  //     setValue("prefix", editAddressItem?.prefix);
  //     setValue(
  //       "address",
  //       selectedAddress ? selectedAddress : specificProvider?.address
  //     );
  //     setValue("city", editAddressItem?.city);
  //   } else {
  //     setValue("email", "");
  //     setValue("englishName", "");
  //     setValue("arabicName", "");
  //     setValue("number", "");
  //     setValue("username", "");
  //     setValue("prefix", "");
  //     setValue("address", "");
  //     setValue("city", "");
  //   }
  // }, [specificProvider, editAddressItem]);

  // useEffect(() => {
  //   if (editAddressItem) {
  //     setEdit(true);
  //     setValue("email", editAddressItem?.email);
  //     setValue("englishName", editAddressItem?.name?.en);
  //     setValue("arabicName", editAddressItem?.name?.ar);
  //     setValue("number", editAddressItem?.number);
  //     setValue("username", editAddressItem?.username);
  //     setValue("prefix", editAddressItem?.prefix);
  //     setValue(
  //       "address",
  //       selectedAddress ? selectedAddress : specificProvider?.address
  //     );
  //     setValue("city", editAddressItem?.city);
  //   } else {
  //     setEdit(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   setAddress({
  //     englishName,
  //     arabicName,
  //     email,
  //     number,
  //     username,
  //     prefix,
  //     _id: editAddressItem?._id,
  //   });
  // }, [flag]);
  // console.log("data:", data);
  // console.log("cityId", cityId);
  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 350,
              height: "90%",
              border: "2px solid #000",
              boxShadow: 24,
              backgroundColor: "white",
              p: 4,
              "& .MuiTextField-root": { m: 0.5 },
              // "& .MuiButton-root": { m: 0.5 },
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              height: "80%",
              backgroundColor: "white",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              "& .MuiTextField-root": { m: 0.5 },
              // "& .MuiButton-root": { m: 0.5 },
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              height: "80%",
              border: "2px solid #000",
              backgroundColor: "white",
              boxShadow: 24,
              p: 4,
              "& .MuiTextField-root": { m: 0.5 },
              // "& .MuiButton-root": { m: 0.5 },
            },
          }}
        >
          <Maps />
        </Box>
      </Modal>
      <div className="branch-add-form-div">
        <form>
          <div className="branch-text-field">
            {" "}
            <div className="branch-text-field-row">
              {" "}
              <TextField
                sx={styleTextField}
                fullWidth
                label="Name*"
                id="fullWidth"
                size="small"
                {...register("englishName", { required: true })}
                error={errors.englishName ? true : false}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.englishName && errors.englishName?.message
                  ? errors.englishName?.message.toString()
                  : null}
              </Typography>
            </div>
            <div className="branch-text-field-row">
              {" "}
              <TextField
                sx={styleTextField}
                fullWidth
                label="اسم*"
                id="fullWidth"
                size="small"
                {...register("arabicName", { required: true })}
                error={errors.arabicName ? true : false}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.arabicName && errors.arabicName?.message
                  ? errors.arabicName?.message.toString()
                  : null}
              </Typography>
            </div>
          </div>
          <div className="branch-text-field">
            <div className="branch-text-field-row">
              {" "}
              <TextField
                sx={styleTextField}
                fullWidth
                label="Email*"
                id="fullWidth"
                size="small"
                {...register("email", { required: true })}
                error={errors.email ? true : false}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.email && errors.email?.message
                  ? errors.email?.message.toString()
                  : null}
              </Typography>
            </div>
            <div className="branch-text-field-row">
              <TextField
                sx={styleTextField}
                fullWidth
                label="Username*"
                size="small"
                {...register("username", { required: true })}
                error={errors.username ? true : false}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.username && errors.username?.message
                  ? errors.username?.message.toString()
                  : null}
              </Typography>
            </div>
          </div>

          <div className="branch-text-field">
            {" "}
            {/* <TextField
              fullWidth
              label="Password*"
              id="fullWidth"
              {...register("password", { required: true })}
              error={errors.password ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.password && errors.password?.message
                ? errors.password?.message.toString()
                : null}
            </Typography> */}
            <div className="branch-text-field-row">
              <TextField
                sx={styleTextField}
                fullWidth
                label="Prefix*"
                size="small"
                {...register("prefix", { required: true })}
                error={errors.prefix ? true : false}
                onChange={(newValue) => setCheckPrefix(newValue.target.value)}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.prefix && errors.prefix?.message
                  ? errors.prefix?.message.toString()
                  : null}
              </Typography>
              {prefixCheck?.data?.length > 0 && (
                <Typography
                  variant="inherit"
                  color="textSecondary"
                  sx={{ textAlign: "center", color: "red" }}
                >
                  Prefix already taken!!
                </Typography>
              )}
            </div>
            <div className="branch-text-field-row">
              {" "}
              <TextField
                sx={styleTextField}
                fullWidth
                label="Phone Number*"
                id="fullWidth"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+971</InputAdornment>
                  ),
                }}
                {...register("number", { required: true })}
                error={errors.number ? true : false}
                onChange={(newValue) => setCheckNumber(newValue.target.value)}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.number && errors.number?.message
                  ? errors.number?.message.toString()
                  : null}
              </Typography>
              {numberCheck?.data?.length > 0 && (
                <Typography
                  variant="inherit"
                  color="textSecondary"
                  sx={{ textAlign: "center", color: "red" }}
                >
                  Number already taken!!
                </Typography>
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showNumber}
                      onChange={handleSwitchChange}
                    />
                  }
                  label="Show Number"
                  labelPlacement="start"
                />
              </FormGroup>
            </div>
          </div>
          <div className="branch-text-field">
            <div className="branch-text-field-row">
              <Autocomplete
                options={data || []}
                fullWidth
                size="small"
                value={citySetting?.data?.label || ""}
                onChange={(event, newValue) => {
                  setCityId(newValue?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select City"
                    size="small"
                    sx={styleTextField}
                    // onChange={(newValue) =>
                    //   setSearchCity(newValue.target.value)
                    // }
                  />
                )}
              />
              {/* <TextField
                sx={styleTextField}
                fullWidth
                label="City"
                size="small"
                {...register("city", { required: true })}
                error={errors.city ? true : false}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.city && errors.city?.message
                  ? errors.city?.message.toString()
                  : null}
              </Typography> */}
            </div>
            <div className="branch-text-field-row">
              {" "}
              <TextField
                sx={styleTextField}
                fullWidth
                multiline
                label="Address*"
                size="small"
                {...register("branchAddress", { required: true })}
                error={errors.branchAddress ? true : false}
                onClick={() => {
                  // setbranchAddress({
                  //   englishName,
                  //   arabicName,
                  //   email,
                  //   number,
                  //   username,
                  //   prefix,
                  //   _id: editbranchAddressItem?._id,
                  // });
                  localStorage.setItem("edit", edit);
                  localStorage.setItem("englishName", englishName);
                  localStorage.setItem("arabicName", arabicName);
                  localStorage.setItem("email", email);
                  localStorage.setItem("number", number);
                  localStorage.setItem("username", username);
                  localStorage.setItem("prefix", prefix);
                  localStorage.setItem("city", cityId);

                  history.push("/map");
                }}
              />
              <Typography
                variant="inherit"
                color="textSecondary"
                sx={{ textAlign: "center" }}
              >
                {errors && errors.branchAddress && errors.branchAddress?.message
                  ? errors.branchAddress?.message.toString()
                  : null}
              </Typography>
            </div>
          </div>

          <Box
            textAlign="center"
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
            }}
          >
            {!loading ? (
              <Button
                variant="contained"
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    backgroundColor: "#190c33",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#190c33",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#190c33",
                  },
                }}
                onClick={() => {
                  localStorage.removeItem("_id");
                  localStorage.removeItem("englishName");
                  localStorage.removeItem("arabicName");
                  localStorage.removeItem("email");
                  localStorage.removeItem("number");
                  localStorage.removeItem("username");
                  localStorage.removeItem("prefix");
                  localStorage.removeItem("mapAddress");
                  localStorage.removeItem("mapGeoCode");
                  localStorage.removeItem("city");
                  localStorage.removeItem("edit");
                  //console.log("removed");
                  setOpen(false);
                }}
                style={{ fontFamily: "outfit" }}
              >
                Close
              </Button>
            ) : (
              ""
            )}
            {loading ? (
              <LoadingButton
                size="small"
                color="secondary"
                // onClick={handleClick}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
              >
                <span>Save</span>
              </LoadingButton>
            ) : (
              <Button
                variant="contained"
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    backgroundColor: "#2b87ae",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#2b87ae",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    backgroundColor: "#2b87ae",
                  },
                }}
                onClick={handleSubmit(addNewBranch)}
                style={{ fontFamily: "outfit" }}
              >
                Save
              </Button>
            )}
          </Box>
        </form>
      </div>
    </>
  );
};

export default AddBranch;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleTextField = {
  "@media (max-width: 600px)": {
    "& .MuiInputBase-input": {
      height: "40px", // Adjust the height value as per your requirement
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    "& .MuiInputBase-input": {
      height: "50px", // Adjust the height value as per your requirement
    },
  },
  "@media (min-width: 970px)": {
    "& .MuiInputBase-input": {
      height: "50px", // Adjust the height value as per your requirement
    },
  },
};
