import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../Stores/auth.slice";
import { app } from "../../Services/dbService";
import { Button } from "@mui/material";
import Lottie from "lottie-react";
import emailSend from "../../lottie/email-send.json";

//import { Link } from "react-router-dom";
const ForgotPassword = ({ history }) => {
  const [email, setEmail] = useState("");
  const [result, SetResult] = useState({});
  const dispatch = useDispatch();
  const onSubmit = async (e) => {
    e.preventDefault();
    const result = await dispatch(
      authActions.resetPasswordSendEmail({ email })
    );
    // console.log("forgot send :", result);
    SetResult(result);
    return result;
  };
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div
              className="authincation-content"
              style={{
                marginTop: "50%",
              }}
            >
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h4 className="text-center mb-4">Forgot Password</h4>
                    <form>
                      <div className="form-group">
                        <label>
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter Email Here"
                        />
                      </div>
                      {result?.type == "auth/resetSend/fulfilled" ? (
                        ""
                      ) : (
                        <div className="text-center">
                          <Button
                            variant="outlined"
                            onClick={(e) => onSubmit(e)}
                          >
                            Submit
                          </Button>
                        </div>
                      )}
                    </form>
                  </div>
                  {result?.type == "auth/resetSend/fulfilled" && (
                    <div
                      className="text-center"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "center",
                        // gap: "10px",
                      }}
                    >
                      <h5>Confirmation - Link Sent to Your Email </h5>
                      <Lottie
                        animationData={emailSend}
                        loop={true}
                        style={{ width: "150px", height: "150px" }}
                      />
                    </div>
                  )}
                  {result?.type == "auth/resetSend/rejected" && (
                    <div className="text-center">
                      <h3>Something Went Wrong Please Try Again! </h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
