import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../Stores/auth.slice";
import { app } from "../../Services/dbService";
import "../../css/signin.css";
import logo from "../../images/logo.png";
import logotext from "../../images/logo-text.png";
import login from "../../images/bg-login2.png";
import loginbg from "../../images/bg-login.jpg";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

function Login(props) {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const [showError, setShowError] = useState(false);

  async function onLogin(e) {
    try {
      e.preventDefault();
      let error = false;
      const errorObj = { ...errorsObj };
      if (email === "") {
        errorObj.email = "Email is Required";
        error = true;
      }
      if (password === "") {
        errorObj.password = "Password is Required";
        error = true;
      }
      setErrors(errorObj);
      if (error) {
        return;
      }
      const result = await dispatch(authActions.login({ email, password }));
      // console.log("result:", result);
      if (result.type === "auth/login/fulfilled") {
        history.push("/");
      }
      if (result.type === "auth/login/rejected") {
        setShowError(true);
      }
      return result;
    } catch (error) {
      // console.log("login:", error);
    }
  }

  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowError(false);
    setPassword("");
    setEmail("");
  };

  // console.log("currentUser::", app.currentUser);

  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={4000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            invalid email/password
          </Alert>
        </Snackbar>
      </Stack>

      <div
        className="login-main-page"
        style={{ backgroundImage: "url(" + loginbg + ")" }}
      >
        <div className="login-wrapper">
          <div
            className="login-aside-left"
            // style={{ backgroundImage: "url(" + login + ")" }}
          >
            <Link to="/" className="login-logo">
              <img src={logo} alt="" className="logo" />
              <h5 className="logo-text">For Business</h5>
              {/* <img src={logotext} alt="" className="ml-1" /> */}
            </Link>
            {/* <div className="login-description">
            <h2 className="text-black  mb-2">Check the Status</h2>
            <p className="fs-12 text-black">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters,
            </p>
            <ul className="social-icons mt-4">
              <li>
                <Link to={"#"}>
                  <i className="fa fa-facebook"></i>
                </Link>
              </li>
              <li>
                <Link to={"#"}>
                  <i className="fa fa-twitter"></i>
                </Link>
              </li>
              <li>
                <Link to={"#"}>
                  <i className="fa fa-linkedin"></i>
                </Link>
              </li>
            </ul>
            <div className="mt-5">
              <Link to={"#"} className="text-black mr-4">
                Privacy Policy
              </Link>
              <Link to={"#"} className="text-black mr-4">
                Contact
              </Link>
              <Link to={"#"} className="text-black">
                © 2021 DexignZone
              </Link>
            </div>
          </div> */}
          </div>
          <div
            className="login-aside-right"
            style={{ backgroundColor: "#2b87ae" }}
          >
            <div className="row m-0 justify-content-center h-100 align-items-center">
              <div className="col-xl-9 col-xxl-9">
                <div className="authincation-content">
                  <div className="row no-gutters">
                    <div className="col-xl-12">
                      <div className="auth-form-1">
                        <div className="mb-4">
                          <h3 className="text-white mb-1">
                            Welcome to Loko Provider
                          </h3>
                          <p className="text-white">
                            Sign in by entering information below
                          </p>
                        </div>
                        {props.errorMessage && (
                          <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                            {props.errorMessage}
                          </div>
                        )}
                        {props.successMessage && (
                          <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                            {props.successMessage}
                          </div>
                        )}
                        <form onSubmit={onLogin}>
                          <div className="form-group">
                            <label className="mb-2 ">
                              <strong className="text-white">Email</strong>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && (
                              <div className="text-danger fs-12">
                                {errors.email}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <label className="mb-2 ">
                              <strong className="text-white">Password</strong>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            {errors.password && (
                              <div className="text-danger fs-12">
                                {errors.password}
                              </div>
                            )}
                          </div>
                          {/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
                          <div className="form-group">
                            <div className="custom-control custom-checkbox ml-1 ">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="basic_checkbox_1"
                              />
                              <label
                                className="form-check-label text-white"
                                htmlFor="basic_checkbox_1 "
                              >
                                Remember my preference
                              </label>
                            </div>
                          </div>
                        </div> */}
                          <div className="text-center">
                            <button
                              type="submit"
                              style={{ color: "#2b87ae" }}
                              className="btn bg-white btn-block"
                            >
                              Sign In
                            </button>
                          </div>
                        </form>
                        <div className="new-account mt-2">
                          <p className="text-white">
                            Don't have an account?{" "}
                            <Link className="text-white" to="./page-register">
                              Sign up
                            </Link>
                          </p>
                        </div>
                        <div className="new-account mt-2">
                          <p className="text-white">
                            {/* Forgotten Password??{" "} */}
                            <Link
                              className="text-white"
                              to="./page-forgot-password"
                            >
                              Forgot Password
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// const mapStateToProps = (state) => {
//     return {
//         errorMessage: state.auth.errorMessage,
//         successMessage: state.auth.successMessage,
//         showLoading: state.auth.showLoading,
//     };
// };
export default Login;
