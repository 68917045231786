import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { formatDate, formatCurrency } from "../../../Services/functions";
import "../../../css/orders.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";

import {
  useCompletedOrders,
  useCurrentProvider,
  useOngoingOrders,
} from "../../../Common/Hooks/queryCalls";
import { LottieLoading } from "../../../Common/LottieLoading";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Orders = () => {
  const history = useHistory();
  const currentProvider = useCurrentProvider();
  // const providerId = "5eda1de77e3e21512c21940f";
  const completedOrders = useCompletedOrders({
    providerId: currentProvider?.data?._id,
  });
  // console.log("useCompletedOrders::", completedOrders.data);
  const ongoingOrders = useOngoingOrders({
    providerId: currentProvider?.data?._id,
  });
  // console.log("useOngoingOrders::", ongoingOrders.data);
  const [selectedItem, setSelectedItem] = useState({});
  const [open, setOpen] = useState(false);
  // console.log("selectedItem:", selectedItem);

  useEffect(() => {
    setOpen(false);
  }, []);

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedItem({});
    setOpen(false);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  if (completedOrders.isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    );
  }
  // if (!completedOrders.data) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       Loading
  //     </h1>
  //   );
  // }

  if (ongoingOrders.isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    );
  }
  // if (!ongoingOrders.data) {
  //   return (
  //     <h1
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       Loading
  //     </h1>
  //   );
  // }

  return (
    <>
      <div className="orders-div">
        <div className="orders-list">
          <Box
            sx={{
              "@media (max-width: 600px)": {
                fontSize: "0.6rem",
                bgcolor: "background.paper",
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "0.8rem",
                bgcolor: "background.paper",
              },
              "@media (min-width: 970px)": {
                fontSize: "1rem",
                bgcolor: "background.paper",
              },
            }}
          >
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                sx={{ backgroundColor: "#2b87ae" }}
              >
                <Tab
                  sx={tableHeadStyle}
                  label="Upcoming Orders"
                  {...a11yProps(0)}
                  style={{ fontFamily: "outfit" }}
                />
                <Tab
                  sx={tableHeadStyle}
                  label="Completed Orders"
                  {...a11yProps(1)}
                  style={{ fontFamily: "outfit" }}
                />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                {ongoingOrders?.data?.length == 0 && (
                  <caption style={{ display: "flex", fontFamily: "outfit" }}>
                    No Upcoming Orders Available
                  </caption>
                )}
                <Box
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      overflowY: "scroll",
                      maxHeight: "calc(90vh - 100px)",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      overflowY: "scroll",
                      maxHeight: "calc(90vh - 100px)",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "1rem",
                      overflowY: "scroll",
                      maxHeight: "calc(90vh - 100px)",
                    },
                  }}
                >
                  {ongoingOrders?.data?.map((item) => {
                    return (
                      <div key={item._id} style={{ margin: "10px" }}>
                        <Card
                        //   className={
                        //     item.status == "completed" ||
                        //     item.status == "accepted" ||
                        //     item.status == "ready"
                        //       ? "order_card_completed"
                        //       : item.status == "pending"
                        //       ? "order_card_pending"
                        //       : "order_card_cancelled"
                        //   }
                        >
                          <CardContent className="order_main_content">
                            <CardContent className="order_card_content_1">
                              <Typography
                                variant="subtitle2"
                                component="div"
                                className="order_no"
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.6rem",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.8rem",
                                    },
                                  "@media (min-width: 970px)": {
                                    fontSize: "1rem",
                                  },
                                }}
                                style={{ fontFamily: "outfit" }}
                              >
                                {item ? item.refNumber : null}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="div"
                                className="order_no"
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.6rem",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.8rem",
                                    },
                                  "@media (min-width: 970px)": {
                                    fontSize: "0.6rem",
                                  },
                                }}
                                style={{ fontFamily: "outfit" }}
                              >
                                {`+971  ${item?.customer?.number}`}
                              </Typography>
                            </CardContent>
                            <CardContent
                              sx={{ margin: 0 }}
                              className="order_card_content_3"
                            >
                              <Button
                                onClick={() => {
                                  history.push(`${item?._id}/order-details`);
                                }}
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.6rem",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.8rem",
                                    },
                                  "@media (min-width: 960px)": {
                                    fontSize: "0.8rem",
                                  },
                                }}
                                style={{ fontFamily: "outfit" }}
                              >
                                view details
                              </Button>
                            </CardContent>
                          </CardContent>
                          {/* <CardActions className="pickup_btn">view details</CardActions> */}
                        </Card>
                      </div>
                    );
                  })}
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                {completedOrders?.data?.length == 0 && (
                  <caption style={{ display: "flex", fontFamily: "outfit" }}>
                    No Completed Orders Available
                  </caption>
                )}
                <Box
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      maxHeight: "calc(90vh - 100px)",
                      overflowY: "scroll",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      maxHeight: "calc(90vh - 100px)",
                      overflowY: "scroll",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "1rem",
                      maxHeight: "calc(90vh - 100px)",
                      overflowY: "scroll",
                    },
                  }}
                >
                  {completedOrders?.data?.map((item) => {
                    return (
                      <div key={item._id} style={{ margin: "10px" }}>
                        <Card
                        //   className={
                        //     item.status == "completed" ||
                        //     item.status == "accepted" ||
                        //     item.status == "ready"
                        //       ? "order_card_completed"
                        //       : item.status == "pending"
                        //       ? "order_card_pending"
                        //       : "order_card_cancelled" calc(80vh - 100px)
                        //   }
                        >
                          <CardContent className="order_main_content">
                            <CardContent className="order_card_content_1">
                              <Typography
                                variant="subtitle2"
                                component="div"
                                className="order_no"
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.6rem",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.8rem",
                                    },
                                  "@media (min-width: 970px)": {
                                    fontSize: "1rem",
                                  },
                                }}
                                style={{ fontFamily: "outfit" }}
                              >
                                {item ? item.refNumber : null}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                component="div"
                                className="order_no"
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.6rem",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.8rem",
                                    },
                                  "@media (min-width: 970px)": {
                                    fontSize: "0.6rem",
                                  },
                                }}
                                style={{ fontFamily: "outfit" }}
                              >
                                {`+971  ${item?.customer?.number}`}
                              </Typography>
                            </CardContent>
                            <CardContent
                              sx={{ margin: 0 }}
                              className="order_card_content_3"
                            >
                              <Button
                                onClick={() => {
                                  history.push(`${item?._id}/order-details`);
                                }}
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.6rem",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.8rem",
                                    },
                                  "@media (min-width: 960px)": {
                                    fontSize: "0.8rem",
                                  },
                                }}
                                style={{ fontFamily: "outfit" }}
                              >
                                view details
                              </Button>
                            </CardContent>
                          </CardContent>
                          {/* <CardActions className="pickup_btn">view details</CardActions> */}
                        </Card>
                      </div>
                    );
                  })}
                </Box>
              </TabPanel>
            </SwipeableViews>
          </Box>
        </div>
      </div>
    </>
  );
};

export default Orders;

const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.775rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.875rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.875rem",
  },
};
