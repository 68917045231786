import React, { useState } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

///Branches
import Branches from "./components/Branches/Branches";

//Addons
import Addons from "./components/Addons/Addons";

//Sub Categories
import SubCategories from "./components/SubCategories/SubCategories";

//Products
import Products from "./components/Products/Products";

//Orders
import Orders from "./components/Orders/Orders";
import OrderDetails from "./components/Orders/OrderDetail";

//Reviews
import Reviews from "./components/Reviews/Reviews";

//Subscription
import Subscription from "./components/Subscription/Subscription";

// Details
import Details from "./components/BasicDetails/Details";
// import Home from "./components/Dashboard/Home/Home";

//maps
import Maps from "./components/Maps/Maps";

//Revenue
import Revenue from "./components/Revenue/Revenue";

/// Pages

import Registration from "./pages/Registration";
import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Todo from "./pages/Todo";
import ForgotPassword from "./pages/ForgotPassword";
import ConfirmResetPassword from "./pages/ConfirmResetPassword";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";

import Protected from "../Services/Protected";
import StripeWindow from "./pages/StripeWindow";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  // console.log('path', path[1]);
  if (path.length > 1) {
    path = path[1];
  } else {
    path = path[path.length - 1];
  }
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    { url: "", component: Details },
    //Branches
    { url: "branches", component: Branches },

    //Addons
    { url: "addons", component: Addons },

    //Sub Categories
    { url: "subcategories", component: SubCategories },

    //Products
    { url: "products", component: Products },

    //Orders
    { url: "orders", component: Orders },
    { url: ":id/order-details", component: OrderDetails },

    //Reviews
    { url: "reviews", component: Reviews },

    //Subscription
    { url: "subscription", component: Subscription },

    // Details
    { url: "basic-details", component: Details },

    // Revenue
    { url: "revenue", component: Revenue },

    //Maps
    { url: "map", component: Maps },
    /// pages
    { url: "page-subscription/:type", component: StripeWindow },
    { url: "page-subscription/:type/:status", component: StripeWindow },
    { url: "page-register", component: Registration },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-confirm-password", component: ConfirmResetPassword },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
    { url: "todo", component: Todo },
  ];

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
